import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { MdAddShoppingCart } from 'react-icons/md';
import {
  BsChevronLeft,
  BsChevronRight,
  BsStar,
  BsStarFill
} from 'react-icons/bs';

import * as Show from '../../../../styles/components/Products/ProductShow/Main';
import {
  addToCart,
  fetchProduct,
  clearProduct,
  displayMessage,
  redirectTo,
  setUpdateProduct
} from '../../../../actions';
import { deleteProduct } from '../../../../helpers/axios';

import {
  HeaderSecondary,
  HeaderTertiary,
  BreadcrumbLink,
  AddToCart,
  AddAmount,
  AddButton
} from '../../../../styles/components/Reusable';

function Main({
  product,
  addToCart,
  fetchProduct,
  clearProduct,
  redirectTo,
  displayMessage,
  setUpdateProduct,
  user
}) {
  let [currentImage, setCurrentImage] = useState(0);
  let [selectedColor, setSelectedColor] = useState('');
  let [addNumber, setAddNumber] = useState(1);

  useEffect(() => {
    return () => {
      clearProduct();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onUpdateClick = async id => {
    try {
      await setUpdateProduct(id);
      redirectTo(`/update-product/${id}`);
    } catch (err) {
      displayMessage(`Couldn't find product to update: ${err}`);
    }
  };

  const onDeleteClick = async id => {
    try {
      if (window.confirm('Are you sure you want to delete this product?')) {
        await deleteProduct(id);

        displayMessage(`Product deleted Successfully`, 4);
        redirectTo(`/${product.type}s`);
      } else {
        return;
      }
    } catch (err) {
      displayMessage(`Could not delete Product: ${err.message} :(`, 3, true);
    }
  };

  const renderGallary = () => {
    if (!product.images) return <div>Loading..</div>;
    return product.images.map((img, i) => {
      let dif = i - currentImage;

      return (
        <Show.Img
          role="img"
          aria-label={`Product image ${i}`}
          src={img}
          key={i}
          style={{ transform: `translateX(${100 * dif}%)` }}
        />
      );
    });
  };

  const renderPreview = () => {
    if (!product.images) return <div>Loading...</div>;
    return product.images.map((img, i) => {
      return (
        <Show.ImgPreview
          role="img"
          aria-label={`Small product image preview ${i}`}
          src={img}
          key={Math.random() * 100}
          selected={i === currentImage ? true : false}
          data-position={i}
          tabIndex="0"
          onKeyPress={e => (e.key === 'Enter' ? setCurrentImage(i) : null)}
          onClick={() => setCurrentImage(i)}
        />
      );
    });
  };

  const imgNext = () => {
    let currentIndex = (currentImage += 1);

    if (currentIndex > product.images.length - 1) {
      currentIndex = 0;
    }

    setCurrentImage(currentIndex);
  };

  const imgPrev = () => {
    let currentIndex = (currentImage -= 1);

    if (currentIndex < 0) {
      currentIndex = product.images.length - 1;
    }

    setCurrentImage(currentIndex);
  };

  const renderColors = () => {
    if (!product.colors) return '';

    const colorArr = product.colors[0].split(',');
    return colorArr.map(color => {
      return (
        <Show.ColorWrapper
          tabIndex="0"
          onKeyPress={e => (e.key === 'Enter' ? setSelectedColor(color) : null)}
          onClick={() => setSelectedColor(color)}
          color={color}
          key={color}
          role="option"
          aria-label={`Product color ${color}`}
          selected={selectedColor === color ? true : false}
        >
          <Show.Color color={color} />
        </Show.ColorWrapper>
      );
    });
  };

  return (
    <Show.Main>
      <Show.Breadcrumb>
        <BreadcrumbLink to="/store">Store</BreadcrumbLink>/
        <BreadcrumbLink to={`/${product.type}s`}>
          {product.type
            ? product.type.charAt(0).toUpperCase() + product.type.slice(1)
            : ''}
          s
        </BreadcrumbLink>
        /<BreadcrumbLink to="#">{product.title}</BreadcrumbLink>
      </Show.Breadcrumb>
      {user ? (
        <Show.AdminWrapper>
          <Show.Update to="#" onClick={() => onUpdateClick(product.id)}>
            Update Product
          </Show.Update>
          <Show.Delete to="#" onClick={() => onDeleteClick(product.id)}>
            Delete Product
          </Show.Delete>
        </Show.AdminWrapper>
      ) : (
        ''
      )}

      <HeaderSecondary>{product.title}</HeaderSecondary>
      <Show.Content>
        <Show.Images>
          <Show.ImageGallary>
            <Show.GallaryLeft>
              <BsChevronLeft
                tabIndex="0"
                onKeyPress={e => (e.key === 'Enter' ? imgPrev() : null)}
                onClick={() => imgPrev()}
              />
            </Show.GallaryLeft>
            {renderGallary()}
            <Show.GallaryRight>
              <BsChevronRight
                tabIndex="0"
                onKeyPress={e => (e.key === 'Enter' ? imgNext() : null)}
                onClick={() => imgNext()}
              />
            </Show.GallaryRight>
          </Show.ImageGallary>
          <Show.GallaryPreview>{renderPreview()}</Show.GallaryPreview>
        </Show.Images>
        <Show.Details>
          <Show.BuyDetails>
            {product.salePrice ? (
              <Show.WasPrice>
                Was <span>${product.salePrice}</span>
              </Show.WasPrice>
            ) : (
              ''
            )}
            <Show.Price>${product.price}</Show.Price>
            <Show.Rating href="#ratings">
              {product.ratingsQuantity ? <BsStarFill /> : <BsStar />}{' '}
              <Show.RatingDetail>
                {product.ratingsAverage || '0'}
              </Show.RatingDetail>
              <Show.RatingDetail>
                {`(${product.ratingsQuantity} reviews)` || '(0 reviews)'}
              </Show.RatingDetail>
            </Show.Rating>
            <HeaderTertiary>Colors</HeaderTertiary>
            <Show.SelectedColor>
              Color:{' '}
              {selectedColor
                ? selectedColor.charAt(0).toUpperCase() + selectedColor.slice(1)
                : 'Please select a color.'}
            </Show.SelectedColor>
            <Show.ColorPicker>{renderColors()}</Show.ColorPicker>
            <Show.CTA>
              {product.disabled ? 'Currently out of stock' : ''}
              <AddToCart>
                <AddAmount
                  onChange={e => setAddNumber(e.target.value)}
                  type="number"
                  value={addNumber}
                />
                <AddButton
                  to={
                    selectedColor && addNumber * 1 && !product.disabled
                      ? '/cart'
                      : '#'
                  }
                  disabled={
                    selectedColor && addNumber && !product.disabled
                      ? false
                      : true
                  }
                  onClick={() =>
                    selectedColor && addNumber && !product.disabled
                      ? addToCart(product.id, addNumber, selectedColor)
                      : ''
                  }
                >
                  <MdAddShoppingCart />
                  Add to Cart
                </AddButton>
              </AddToCart>
            </Show.CTA>
          </Show.BuyDetails>
          <HeaderTertiary>Info</HeaderTertiary>
          <Show.Description>{product.summary}</Show.Description>
        </Show.Details>
      </Show.Content>
    </Show.Main>
  );
}

const mapStateToProps = ({ user }) => ({ user });

export default connect(
  mapStateToProps,
  {
    addToCart,
    fetchProduct,
    clearProduct,
    redirectTo,
    displayMessage,
    setUpdateProduct
  }
)(Main);
