import { REDIRECT_TO } from '../actions/types';

const redirect = (state = null, action) => {
  switch (action.type) {
    case REDIRECT_TO:
      return action.payload || null;
    default:
      return state;
  }
};

export default redirect;
