import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import Dropzone from 'react-dropzone';
import { WithContext as ReactTags } from 'react-tag-input';
import axios from 'axios';

import * as Form from '../../styles/components/Form';
import {
  ButtonSubmit,
  HeaderSecondary
} from '../../styles/components/Reusable';
import { displayMessage, redirectTo, clearUpdate } from '../../actions';

///Redux form validator
const validate = (values) => {
  const errors = {};

  if (!values.title) {
    errors.title = 'Please enter a title.';
  }
  if (!values.type) {
    errors.type = 'Please select a type.';
  }
  if (!values.coverImage) {
    errors.coverImage = 'Please select a cover image.';
  }
  if (!values.images) {
    errors.images = 'Please select some images.';
  }
  if (!values.price) {
    errors.price = 'Please enter a price.';
  }
  if (!values.summary) {
    errors.summary = 'Please enter a summary.';
  }
  if (!values.description) {
    errors.description = 'Please enter a description.';
  }
  if (!values.specs) {
    errors.specs = 'Please provide some specs.';
  }
  if (!values.colors) {
    errors.colors = 'Please provide at least one color.';
  }
  if (!values.disabled) {
    errors.disabled = 'Please select an option.';
  }
  return errors;
};

///Redux form renderers
const renderInput = ({ input, meta: { touched, error }, initial, ...rest }) => (
  <>
    <Form.Input
      onChange={(event, value) => input.onChange(value)}
      error={touched && error}
      {...input}
      {...rest}
    />
    {touched && error && <Form.Error>{error}</Form.Error>}
  </>
);

const renderTextarea = ({ input, meta: { touched, error }, ...rest }) => {
  return (
    <>
      <Form.Textarea
        onChange={(event, value) => input.onChange(value)}
        error={touched && error}
        {...input}
        {...rest}
      />
      {touched && error && <Form.Error>{error}</Form.Error>}
    </>
  );
};

const renderRadioInput = ({ input, meta: { touched, error }, ...rest }) => {
  return (
    <>
      <Form.RadioInput
        onChange={(event, value) => input.onChange(value)}
        error={touched && error}
        {...input}
        {...rest}
      />
      {touched && error && <Form.Error>{error}</Form.Error>}
    </>
  );
};

const renderDropzoneInput = ({
  input,
  meta: { touched, error },
  multiple,
  ...rest
}) => {
  const files = input.value;
  return (
    <div>
      <Dropzone
        {...rest}
        multiple={multiple}
        onDrop={(filesToUpload, e) => input.onChange(filesToUpload)}
      >
        {({ getRootProps, getInputProps }) => {
          return (
            <Form.DropzoneWrapper {...getRootProps()}>
              <Form.DropzoneInput {...getInputProps()} />
              <p>Drag and drop files here, or select them.</p>
            </Form.DropzoneWrapper>
          );
        }}
      </Dropzone>
      {touched && error && <Form.Error>{error}</Form.Error>}
      {files && Array.isArray(files) && typeof files[0] === 'object' && (
        <ul>
          {files.map((file, i) => (
            <li key={i}>{file.name}</li>
          ))}
        </ul>
      )}
    </div>
  );
};

const renderTagInput = ({ input, meta: { touched, error }, ...rest }) => {
  let inputArr = [...input.value];
  let updateValues;
  let updateValuesObj = {};
  if (typeof input.value[0] === 'string') {
    inputArr = [];
    updateValues = input.value[0].split(',');
    updateValues.forEach((value) => {
      updateValuesObj['id'] = value;
      updateValuesObj['text'] = value;

      inputArr.push(updateValuesObj);
      updateValuesObj = {};
    });
  }

  const handleAdd = (tag) => {
    input.onChange([...inputArr, tag]);
  };

  const handleDelete = (i) => {
    const tags = [...inputArr];
    tags.splice(i, 1);
    input.onChange(tags);
  };

  return (
    <>
      <ReactTags
        tags={inputArr || []}
        handleAddition={handleAdd}
        handleDelete={handleDelete}
        {...rest}
      />
      {touched && error && <Form.Error>{error}</Form.Error>}
    </>
  );
};

function Index(props) {
  const { handleSubmit, update, clearUpdate, reset, pristine, submitting } =
    props;
  const { id } = useParams();

  useEffect(() => {
    if (!id) {
      clearUpdate();
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!props.user) {
    return <Redirect to='/new-product ' />;
  }

  return (
    <Form.Container>
      <Form.Form onSubmit={handleSubmit}>
        <HeaderSecondary>
          {update ? 'Update Product' : 'New Product'}
        </HeaderSecondary>
        <Form.Group>
          <Form.Label htmlFor='title'>Title</Form.Label>
          <Field
            type='text'
            label='title'
            id='title'
            initial='Wow'
            name='title'
            component={renderInput}
            placeholder="12' Inflatable Raft"
          />
        </Form.Group>
        <Form.Group>
          <Form.GroupHeader>Type of product</Form.GroupHeader>
          <Form.Label>
            <Form.RadioInputWrapper>
              <Field
                type='radio'
                label='raft'
                id='raft'
                name='type'
                value='raft'
                component={renderRadioInput}
              />
              <Form.RadioInputControl />
            </Form.RadioInputWrapper>
            <Form.RadioSpan>Raft</Form.RadioSpan>
          </Form.Label>
          <Form.Label>
            <Form.RadioInputWrapper>
              <Field
                type='radio'
                label='kayak'
                id='kayak'
                name='type'
                value='kayak'
                component={renderRadioInput}
              />
              <Form.RadioInputControl />
            </Form.RadioInputWrapper>
            <Form.RadioSpan>Kayak</Form.RadioSpan>
          </Form.Label>
          <Form.Label>
            <Form.RadioInputWrapper>
              <Field
                type='radio'
                label='SUP'
                id='SUP'
                name='type'
                value='SUP'
                component={renderRadioInput}
              />
              <Form.RadioInputControl />
            </Form.RadioInputWrapper>
            <Form.RadioSpan>SUP</Form.RadioSpan>
          </Form.Label>
        </Form.Group>
        <Form.Group>
          <Form.GroupHeader>Images</Form.GroupHeader>
          <Form.Label htmlFor='coverImage'>Cover Image</Form.Label>
          <Field
            name='coverImage'
            id='coverImage'
            label='coverImage'
            multiple={false}
            component={renderDropzoneInput}
          />
          <Form.Label htmlFor='images'>Images</Form.Label>
          <Field
            name='images'
            id='images'
            label='images'
            multiple={true}
            maxFiles={10}
            component={renderDropzoneInput}
          />
        </Form.Group>
        <Form.Group>
          <Form.GroupHeader>Price</Form.GroupHeader>
          <Form.Label htmlFor='salePrice'>"Was" Price (optional)</Form.Label>
          <Field
            type='number'
            name='salePrice'
            id='salePrice'
            label='salePrice'
            placeholder='was $1599 (only number)'
            component={renderInput}
          />
          <Form.Label htmlFor='price'>Price</Form.Label>
          <Field
            type='number'
            name='price'
            id='price'
            label='price'
            placeholder='1399'
            component={renderInput}
          />
        </Form.Group>
        <Form.Group>
          <Form.GroupHeader>Summary</Form.GroupHeader>
          <Form.Label htmlFor='summary'>Initial Summary of Product</Form.Label>
          <Field
            name='summary'
            id='summary'
            rows='4'
            col='100'
            placeholder='...type here'
            component={renderTextarea}
          />
        </Form.Group>
        <Form.Group>
          <Form.GroupHeader>Description</Form.GroupHeader>
          <Form.Label htmlFor='description'>Full Description</Form.Label>
          <Field
            name='description'
            id='description'
            rows='10'
            col='50'
            placeholder={`## The best kayak of the century!

Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nostrum obcaecati vel facere eos incidunt consectetur at, illo nisi fuga cum, voluptatum optio laboriosam sed distinctio possimus error repellendus doloremque sint suscipit dolorum molestias aut animi.

### Why should you buy?
            
- Reliable
- Fast
- Cool
---
            
One of the best kayaks available, buy now, or you'll miss your chance!`}
            component={renderTextarea}
          />
          <i>
            The description supports markdown, if you want it to look nice in
            the description.
            <br />
            The placeholder shows an example of how it works.
            <br />
            <a
              href='https://www.markdownguide.org/cheat-sheet/'
              target='_blank'
              rel='noopener noreferrer'
            >
              Cheatsheet for markdown here!
            </a>
          </i>
        </Form.Group>
        <Form.Group>
          <Form.GroupHeader>Specs</Form.GroupHeader>
          <Form.Label htmlFor='specs'>
            Set specs with a space (i.e Length 10')
          </Form.Label>
          <Field
            type='text'
            name='specs'
            id='specs'
            label='specs'
            component={renderTagInput}
          />
          <i>
            Word on the left will be the label of the spec, right will be the
            value. If you want a space in either side,
            <br /> use a '-', and it will be replaced with a space.
          </i>
        </Form.Group>
        <Form.Group>
          <Form.GroupHeader>Colors</Form.GroupHeader>
          <Form.Label htmlFor='colors'>Add a color</Form.Label>
          <Field
            type='text'
            name='colors'
            id='colors'
            label='colors'
            component={renderTagInput}
          />
          <i>
            Please enter colors with a recognized html color name (in
            lowercase), it covers more colors than you'll probably need.
            <br />
            The link below will take you to a nicely organized list.
            <br />
            (You can also use a hex value if you need to be specific, but when a
            user picks a color on the product page,
            <br /> it'll tell them they selected a hex value which might confuse
            people)
          </i>
          <a
            href='https://htmlcolorcodes.com/color-names/'
            target='_blank'
            rel='noreferrer noopener'
          >
            Find colors here!
          </a>
        </Form.Group>
        <Form.Group>
          <Form.GroupHeader>Disable product (out of stock)</Form.GroupHeader>
          <Form.Label htmlFor='true'>
            <Form.RadioInputWrapper>
              <Field
                type='radio'
                label='true'
                id='true'
                name='disabled'
                value='true'
                component={renderRadioInput}
              />
              <Form.RadioInputControl />
            </Form.RadioInputWrapper>
            <Form.RadioSpan>True</Form.RadioSpan>
          </Form.Label>
          <Form.Label htmlFor='false'>
            <Form.RadioInputWrapper>
              <Field
                type='radio'
                label='false'
                id='false'
                name='disabled'
                value='false'
                component={renderRadioInput}
              />
              <Form.RadioInputControl />
            </Form.RadioInputWrapper>
            <Form.RadioSpan>False</Form.RadioSpan>
          </Form.Label>
        </Form.Group>
        <Form.Group>
          <Form.ButtonWrapper>
            <ButtonSubmit
              type='submit'
              value={update ? 'Update product' : 'Add new product'}
              disabled={pristine || submitting}
            />
          </Form.ButtonWrapper>
        </Form.Group>
      </Form.Form>
    </Form.Container>
  );
}

const createNewProduct = async (values) => {
  await axios({
    method: 'POST',
    url: '/api/v1/products',
    data: values
  });
};

const updateProduct = async (values, id) => {
  await axios({
    method: 'PATCH',
    url: `/api/v1/products/${id}`,
    data: values,
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  });
};

const submitNewProduct = async (values, dispatch, props) => {
  try {
    //Setting up form
    const productForm = new FormData();
    //Handle tag inputs
    if (!values.specs || !values.colors)
      throw new SubmissionError({
        specs: 'Please provide some specs',
        color: 'Please provide at least one color'
      });

    if (typeof values.specs[0] === 'string')
      productForm.append('specs', values.specs[0]);
    else {
      const specs = values.specs.map((spec) => spec.text);
      productForm.append('specs', specs);
    }

    if (typeof values.colors[0] === 'string')
      productForm.append('colors', values.colors[0]);
    else {
      const colors = values.colors.map((color) => color.text);
      productForm.append('colors', colors);
    }

    //Adding data to form
    if (typeof values.images[0] === 'string') {
      values.images = [];
    }
    values.images.forEach((file) => productForm.append('images', file));
    productForm.append('title', values.title || '');
    productForm.append('description', values.description || '');
    productForm.append('type', values.type || '');
    //Fixing coverImage for updates
    if (typeof values.coverImage === 'string') {
      values.coverImage = [values.coverImage];
    }
    productForm.append('coverImage', values.coverImage[0] || null);
    productForm.append('salePrice', values.salePrice || 0);
    productForm.append('price', values.price || 0);
    productForm.append('summary', values.summary || 0);
    productForm.append('disabled', values.disabled || false);

    if (!props.update) {
      await createNewProduct(productForm);
      await dispatch(displayMessage('Product created successfully!', 5));
      await dispatch(redirectTo(`/${values.type}s`));
    } else {
      await updateProduct(productForm, props.currentUpdate.id);
      await dispatch(displayMessage('Product updated successfully!', 5));
      await dispatch(redirectTo(`/${values.type}s/${props.currentUpdate.id}`));
    }
  } catch (err) {
    await dispatch(
      displayMessage(
        `${err.response.data.message} Code: ${err.response.status} (If network error, or 401, try logging in again.)`,
        999,
        true
      )
    );
  }
};

const mapStateToProps = ({ user, currentUpdate }) => {
  return { user, currentUpdate, initialValues: currentUpdate };
};

const ConnectedForm = connect(mapStateToProps, { clearUpdate })(
  reduxForm({
    form: 'newProduct',
    onSubmit: submitNewProduct,
    validate,
    enableReinitialize: true
  })(Index)
);

export default ConnectedForm;
