import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import { fetchProduct } from '../../../../actions';
import Main from './Main';
import Info from './Info';
import Reviews from './Reviews';

import { Container } from '../../../../styles/components/Products/ProductShow/Main';

function Index(props) {
  const { product, reviews } = props;
  const { fetchProduct } = props;
  const { id } = useParams();
  useEffect(() => {
    fetchProduct(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <Main product={product} />
      <Info product={product} />
      <Reviews product={product} reviews={reviews} />
    </Container>
  );
}

const mapStateToProps = ({ currentProduct, reviews }) => {
  return { product: currentProduct, reviews };
};

export default connect(
  mapStateToProps,
  { fetchProduct }
)(Index);
