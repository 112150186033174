import styled, { keyframes } from 'styled-components';
import { device } from '../../devices';
import { Button } from '../Reusable';

const pulsate = keyframes`
  0% {
    transform: scale(1)
  }

  50% {
    transform: scale(1.15)
  }

  100% {
    transform: scale(1)
  }
`;

export const Container = styled.div`
  min-height: 55vh;
  padding: 2rem;

  @media ${device.mobileL} {
    padding: 0;
  }
`;

export const Main = styled.div`
  width: 90%;
  margin: 8rem auto;
  background-color: ${(props) => props.theme.colorLightGray3};
  padding-top: 2rem;
  padding-bottom: 5rem;
  padding-left: 3rem;
  padding-right: 3rem;

  @media ${device.mobileL} {
    width: 95%;
    padding: 1rem;
  }
`;

export const Card = styled.div`
  margin: 0 auto;
  display: flex;
  padding: 1rem;
  box-shadow: ${(props) => props.theme.shadowLight};
  border-radius: 4px;

  background-color: ${(props) => props.theme.colorLightGray1};

  @media ${device.laptop} {
    flex-direction: column;
  }
`;

export const CoverImg = styled.img`
  height: 25rem;
  flex: 0 0 25%;
  overflow: hidden;
  border-radius: 5px;

  box-shadow: ${(props) => props.theme.shadowLight};
`;

export const ItemDetails = styled.div`
  flex: 0 0 55%;
  margin: 2rem;
  font-size: 1.8rem;
  padding: 2rem;
  border-radius: 2%;

  @media ${device.mobileL} {
    padding: 0;
    margin: 1rem;
  }
`;

export const Title = styled.h3`
  font-size: 2.2rem;
  font-weight: 400;
  color: ${(props) => props.theme.colorPrimary};
  margin-bottom: 1.5rem;
`;

export const Description = styled.p``;

export const PriceWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  padding: 2rem;
  font-size: 2.5rem;

  @media ${device.laptop} {
    flex: 0;
    align-items: flex-start;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }
`;

export const SalePrice = styled.span`
  text-decoration: line-through;
  color: ${(props) => props.theme.colorTertiaryDark};
  display: block;
`;

export const Price = styled.span`
  color: ${(props) => props.theme.colorTertiaryLight};
  animation: 1s ${pulsate} infinite;
  display: block;
`;

export const BuyButton = styled(Button)`
  width: 95%;
  color: ${(props) => props.theme.colorLightGray1};
  box-shadow: ${(props) => props.theme.shadowLight};
  margin-top: 2rem;

  @media ${device.laptop} {
    flex: 0 0 50%;
  }

  @media ${device.mobileL} {
    margin-top: 0;
  }
`;
