import { combineReducers } from 'redux';
import { reducer as reduxForm } from 'redux-form';

import authReducer from './authReducer';
import cookieReducer from './cookieReducer';
import redirectReducer from './redirectReducer';
import productsReducer from './productsReducer';
import productReducer from './productReducer';
import cartReducer from './cartReducer';
import reviewsReducer from './reviewsReducer';
import messageReducer from './messageReducer';
import orderReducer from './orderReducer';
import updateReducer from './updateReducer';

export default combineReducers({
  form: reduxForm,
  user: authReducer,
  redirect: redirectReducer,
  products: productsReducer,
  currentProduct: productReducer,
  currentUpdate: updateReducer,
  reviews: reviewsReducer,
  orders: orderReducer,
  cart: cartReducer,
  message: messageReducer,
  cookiesAccepted: cookieReducer
});
