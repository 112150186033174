import styled from 'styled-components';
import { device } from '../../../devices';
import { Button } from '../../Reusable';

export const Container = styled.div`
  background-color: ${(props) => props.theme.colorLightGray3};
  margin-top: 8rem;
  padding-top: 4rem;
  padding-bottom: 4rem;
`;

export const Main = styled.div`
  width: 95%;
  margin: 0 auto;

  position: relative;

  padding-top: 4rem;

  display: flex;
  flex-direction: column;
  align-items: center;

  box-shadow: ${(props) => props.theme.shadowLight};

  background-color: ${(props) => props.theme.colorLightGray1};
`;

export const Content = styled.div`
  margin-top: -4rem;
  display: flex;

  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media ${device.tabletL} {
    flex-direction: column;
  }
`;

export const Breadcrumb = styled.div`
  font-size: 2rem;
  color: ${(props) => props.theme.colorPrimaryLight};
  padding-top: 1rem;
  padding-left: 1rem;

  position: absolute;

  top: 0;
  left: 0;
`;

export const AdminWrapper = styled.div`
  position: relative;
  right: 0;
  top: 1rem;

  padding: 0 1rem;

  display: flex;
  justify-content: space-between;

  @media ${device.mobileM} {
    margin-top: 3rem;
  }
`;

export const Update = styled(Button)`
  background-color: #ffd700;
  background-image: none;

  margin-right: 2rem;

  text-shadow: ${(props) => props.theme.shadowLight};

  &:hover {
    background-image: none;
    background-color: #ffd700;

    filter: brightness(105%);
  }

  @media ${device.mobileM} {
    margin-right: 0.5rem;
    font-size: 1.5rem;

    padding: 1rem 0.5rem;
  }
`;

export const Delete = styled(Button)`
  background-color: ${(props) => props.theme.colorError};
  background-image: none;

  text-shadow: ${(props) => props.theme.shadowLight};

  &:hover {
    background-image: none;
    background-color: ${(props) => props.theme.colorError};

    filter: brightness(105%);
  }

  @media ${device.mobileM} {
    margin-right: 0.5rem;
    font-size: 1.5rem;

    padding: 1rem 0.5rem;
  }
`;

export const Images = styled.div`
  flex: 0 0 50%;
  display: flex;

  flex-direction: column;
  margin-top: 2rem;
  margin: 2rem;

  padding-right: 4rem;
  border-right: 1px solid rgba(${(props) => props.theme.colorPrimaryRGB}, 0.3);

  @media ${device.tabletL} {
    flex: 0;
    width: 90%;

    padding-right: 0;
    border: none;

    margin: 2rem auto;
  }
`;

export const ImageGallary = styled.div`
  width: 100%;
  height: 40rem;

  border-radius: 1rem;

  overflow: hidden;

  position: relative;

  box-shadow: ${(props) => props.theme.shadowDark};
`;

export const GallaryLeft = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 45%;

  height: 6rem;
  width: 6rem;
  color: ${(props) => props.theme.colorPrimaryLight};
  text-shadow: ${(props) => props.theme.shadowDark};
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;

  z-index: 100;

  transition: all 0.3s;

  & > svg {
    height: 5.5rem;
    width: 5.5rem;

    transition: all 0.3s;

    &:hover {
      height: 6rem;
      width: 6rem;
    }
  }

  &:hover {
    height: 6.5rem;
    width: 6.5rem;
  }

  &:active {
    transform: translateX(-3px);
  }
`;

export const GallaryRight = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 45%;
  right: 0px;

  height: 6rem;
  width: 6rem;

  color: ${(props) => props.theme.colorPrimaryLight};
  text-shadow: ${(props) => props.theme.shadowDark};

  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;

  z-index: 100;

  transition: all 0.3s;

  & > svg {
    height: 5.5rem;
    width: 5.5rem;

    transition: all 0.3s;

    &:hover {
      height: 6rem;
      width: 6rem;
    }
  }

  &:hover {
    height: 6.5rem;
    width: 6.5rem;
  }

  &:active {
    transform: translateX(3px);
  }
`;

export const Img = styled.div`
  position: absolute;

  transition: all 0.3s;

  height: 100%;
  width: 100%;

  background-image: url('${(props) => props.src}');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`;

export const GallaryPreview = styled.div`
  min-height: 15rem;
  width: 100%;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  transition: all 0.3s;
`;

export const ImgPreview = styled.div`
  flex: 0 0 20%;
  height: 12rem;
  margin: 1.5rem;

  border-radius: 0.5rem;

  opacity: ${(props) => (props.selected ? 1 : 0.7)};

  background-image: url('${(props) => props.src}');
  background-size: auto 100%;
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: ${(props) => props.theme.shadowDark};

  cursor: ${(props) => (props.selected ? '' : 'pointer')};

  border: ${(props) =>
    props.selected ? `3px solid ${props.theme.colorPrimary}` : 'none'};

  transition: all 0.3s;

  &:hover {
    transform: ${(props) => (props.selected ? '' : 'scale(1.1)')};
  }

  @media ${device.mobileM} {
    flex: 0 0 40%;
  }
  @media ${device.mobileS} {
    margin: 0.5rem;
  }
`;

export const Details = styled.div`
  width: 50%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  margin-left: 5rem;
  margin-top: 5rem;

  @media ${device.tabletL} {
    width: 80%;

    margin: 3rem 0;
  }
  @media ${device.mobileS} {
    width: 90%;

    margin: 3rem 0;
  }
`;

export const BuyDetails = styled.div`
  width: 100%;
`;

export const WasPrice = styled.span`
  font-size: 2.5rem;
  display: block;
  color: ${(props) => props.theme.colorTertiaryLight};

  & > span {
    opacity: 0.6;
    text-decoration: line-through;
    margin-left: 0.5rem;
  }
`;

export const Price = styled.span`
  font-size: 4.5rem;
  color: ${(props) => props.theme.colorTertiaryLight};

  text-shadow: ${(props) => props.theme.shadowLight};
`;

export const Rating = styled.a`
  display: block;

  font-size: 1.8rem;

  margin-left: 1rem;
  margin-bottom: 2rem;

  transition: all 0.2s;

  &:link,
  &:visited {
    color: ${(props) => props.theme.colorPrimary};
    text-decoration: none;
    text-shadow: ${(props) => props.theme.shadowVeryLight};

    &:hover {
      transform: skew(4deg);
      font-size: 2rem;
    }
  }

  & > svg {
    color: ${(props) => props.theme.colorPrimaryDark};
    font-size: 2rem;
  }
`;

export const RatingDetail = styled.span`
  &:not(:last-child) {
    margin-right: 0.5rem;
  }
`;

export const ColorPicker = styled.div`
  margin: 2rem 0;
  width: 50%;

  display: flex;
  flex-wrap: wrap;

  padding-bottom: 2rem;
  border-bottom: 1px solid ${(props) => props.theme.colorPrimary};
  @media ${device.mobileS} {
    width: 70%;
  }
`;

export const ColorWrapper = styled.div`
  height: 3rem;
  width: 3rem;
  margin-right: 2rem;

  border: 3px solid
    ${(props) =>
      !props.selected
        ? props.theme.colorGrayDark1
        : props.theme.colorPrimaryLight};
  border-radius: 2px;

  box-shadow: ${(props) => props.theme.shadowLight};

  display: flex;
  justify-content: center;
  align-items: center;

  transition: all 0.2s;

  &:hover,
  &:focus {
    outline: none;
    transform: scale(1.1);
    border: 3px solid ${(props) => props.theme.colorPrimaryDark};
  }
`;

export const Color = styled.div`
  height: 2rem;
  width: 2rem;
  background-color: ${(props) => props.color};
  border-radius: 1px;
`;

export const SelectedColor = styled.span`
  font-size: 2rem;
  font-weight: 700;
`;

export const CTA = styled.div`
  width: 50%;
  margin: 3rem 0;

  @media ${device.mobileS} {
    width: 80%;
  }
`;

export const Description = styled.p`
  width: 80%;
`;
