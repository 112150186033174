import React from 'react';
import { BsArrowRightShort } from 'react-icons/bs';

import * as Feat from '../../styles/components/Landing/Featured';
import { HeaderSecondary, ShopIcon } from '../../styles/components/Reusable';

export default function Featured() {
  return (
    <Feat.Container>
      <Feat.Main>
        <HeaderSecondary marBot='2rem' light>
          On Sale Now!
        </HeaderSecondary>
        <Feat.Card>
          <Feat.CoverImg src='/img/featured.png' />
          <Feat.ItemDetails>
            <Feat.Title>12` Hoback Raft</Feat.Title>
            <Feat.Description>
              {' '}
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio,
              quasi? Eum voluptate recusandae necessitatibus voluptatem velit.
              Omnis saepe suscipit impedit optio eius consequuntur illo
              voluptate nam quidem! Ullam, unde impedit.{' '}
            </Feat.Description>
          </Feat.ItemDetails>
          <Feat.PriceWrapper>
            <div>
              <Feat.SalePrice>$2100</Feat.SalePrice>
              <Feat.Price>$1999.99</Feat.Price>
            </div>
            <Feat.BuyButton to='/rafts/612594471a17cb001611b8fb'>
              Buy now
              <ShopIcon>
                <BsArrowRightShort />
              </ShopIcon>
            </Feat.BuyButton>
          </Feat.PriceWrapper>
        </Feat.Card>
      </Feat.Main>
    </Feat.Container>
  );
}
