import React, { useEffect, useState } from 'react';
import { BiDownArrow } from 'react-icons/bi';
import { BsStar, BsStarFill } from 'react-icons/bs';
import { MdAddShoppingCart } from 'react-icons/md';
import { connect } from 'react-redux';

import { scrollToTop } from '../../../helpers';
import { fetchProducts, clearProducts, addToCart } from '../../../actions';
import * as Product from '../../../styles/components/Products';
import {
  Button,
  HeaderPrimary,
  Heading,
  BreadcrumbLink,
  AddToCart,
  AddAmount,
  AddButton
} from '../../../styles/components/Reusable';

function RaftLanding(props) {
  let [page, setPage] = useState('1');
  let [sort, setSort] = useState('-createdAt');
  let [addNumber, setAddNumber] = useState(1);

  const { products, addToCart } = props;
  useEffect(() => {
    props.fetchProducts('raft', page, sort);

    return () => {
      props.clearProducts();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, sort]);

  const renderProducts = () => {
    if (!products.data) {
      return <div>Loading...</div>;
    }
    return products.data.products.map((product, i) => {
      return (
        <Product.Card key={i}>
          <Product.CoverImg src={product.coverImage} />
          <Product.DetailWrapper>
            <Product.TitleWrapper>
              <Product.AverageRating>
                <Product.StarWrapper>
                  {!product.ratingsAverage ? <BsStar /> : <BsStarFill />}
                </Product.StarWrapper>

                {` ${product.ratingsAverage ||
                  '0'} (${product.ratingsQuantity || 0} review(s))`}
              </Product.AverageRating>
              <Product.Title>{product.title}</Product.Title>
            </Product.TitleWrapper>
            <Product.PriceWrapper>
              {product.salePrice ? (
                <Product.SalePrice>${product.salePrice}</Product.SalePrice>
              ) : (
                ''
              )}
              <Product.Price>${product.price}</Product.Price>
            </Product.PriceWrapper>
          </Product.DetailWrapper>
          <Product.ButtonWrapper>
            <Button to={`/rafts/${product.id}`} onClick={scrollToTop}>
              Details
            </Button>
            <AddToCart>
              <AddAmount
                onChange={e => setAddNumber(e.target.value)}
                type="number"
                value={addNumber}
              />
              <AddButton
                secondary="true"
                to={addNumber * 1 && !product.disabled ? '/cart' : '#'}
                disabled={addNumber * 1 && !product.disabled ? false : true}
                onClick={() => {
                  if (addNumber && !product.disabled) {
                    addToCart(product.id, addNumber);
                    scrollToTop();
                  }
                }}
              >
                <MdAddShoppingCart />
                Add to Cart
              </AddButton>
            </AddToCart>
          </Product.ButtonWrapper>
          {product.disabled ? 'Currently out of stock.' : null}
        </Product.Card>
      );
    });
  };

  const renderPaginate = () => {
    const renderLinks = () => {
      const pages = Math.ceil(products.totalResults / 9);
      let links = [];
      for (let i = 1; i <= pages; i++) {
        links.push(
          <Product.PageLink key={`page ${i}`} onClick={() => setPage(i)}>
            {i}
          </Product.PageLink>
        );
      }
      return links;
    };
    return (
      <Product.Paginate>
        <Product.PageMove
          onClick={() => (page > 1 ? setPage((page -= 1)) : null)}
        >
          Prev
        </Product.PageMove>
        {renderLinks()}
        <Product.PageMove
          onClick={() =>
            page < products.totalResults / 9 ? setPage((page += 1)) : null
          }
        >
          Next
        </Product.PageMove>
      </Product.Paginate>
    );
  };

  const sortProducts = value => {
    setSort(value);
    props.fetchProducts('raft', page, sort);
  };

  return (
    <Product.Container>
      <Heading src="/img/raft-landing.jpg" position="bottom">
        <HeaderPrimary>Rafts</HeaderPrimary>
      </Heading>

      <Product.Options>
        <div>
          <BreadcrumbLink to="/store">Store</BreadcrumbLink>/
          <BreadcrumbLink to="#">Rafts</BreadcrumbLink>
        </div>
        <Product.DropdownWrapper>
          <Product.SortLabel htmlFor="sort">Sort by</Product.SortLabel>
          <Product.SortWrapper>
            <Product.SortDropdown
              name="sort"
              id="sort"
              onChange={e => sortProducts(e.target.value)}
            >
              <Product.DropdownItem value="-createdAt">
                Most Recent
              </Product.DropdownItem>
              <Product.DropdownItem value="-ratingsAverage -ratingsQuantity">
                Highest Rating
              </Product.DropdownItem>
              <Product.DropdownItem value="price">
                Lowest Price
              </Product.DropdownItem>
              <Product.DropdownItem value="-price">
                Highest Price
              </Product.DropdownItem>
            </Product.SortDropdown>
            <BiDownArrow />
          </Product.SortWrapper>
        </Product.DropdownWrapper>
      </Product.Options>
      <Product.Length>Total results: {products.totalResults}</Product.Length>
      <Product.Main>{renderProducts()}</Product.Main>
      {renderPaginate()}
    </Product.Container>
  );
}

const mapStateToProps = ({ products }) => {
  return { products };
};

export default connect(
  mapStateToProps,
  { fetchProducts, clearProducts, addToCart }
)(RaftLanding);
