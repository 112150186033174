import React from 'react';
import Media from 'react-media';
import { device } from '../../styles/devices';

import * as Who from '../../styles/components/Landing/WhoWeAre';
import { HeaderSecondary } from '../../styles/components/Reusable';

export default function Featured() {
  const renderCircle = () => {
    return (
      <Who.ImageCircle>
        <Who.Img
          src='/img/why-us.jpg'
          alt='People rafting down a river rapid.'
        />
      </Who.ImageCircle>
    );
  };

  return (
    <Who.Container id='why-us'>
      <Who.Main>
        <HeaderSecondary>Why choose us?</HeaderSecondary>
        <Who.ContentWrapper>
          <Media query={`${device.tabletL}`} render={renderCircle} />
          <Who.Content>
            <Who.Text>
              {' '}
              Teton Wilderness Supply was built by a team of whitewater
              enthusiasts, with a primary focus: to help YOU experience the
              outdoors. Today, we've realized that goal, providing
              top-of-the-line, affordable outdoor equipment.{' '}
            </Who.Text>
          </Who.Content>
          <Media query='(min-width: 851px)' render={renderCircle} />
          <Who.Content>
            <Who.Text>
              Dedicated to the highest standards in dependability and
              durability, we strive to provide great equipment for all of your
              outdoor adventures.
            </Who.Text>
          </Who.Content>
        </Who.ContentWrapper>
      </Who.Main>
    </Who.Container>
  );
}
