import styled from 'styled-components';
import { device } from '../../devices';

export const Container = styled.div`
  margin-top: 8rem;
  padding: 2rem;

  display: flex;
  justify-content: center;
  align-items: center;

  min-height: 80vh;
  background-color: ${props => props.theme.colorLightGray3};
`;

export const Form = styled.form`
  background-color: ${props => props.theme.colorLightGray1};
  padding: 3rem 8rem;

  border-radius: 2rem;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  box-shadow: ${props => props.theme.shadowLight};

  @media ${device.laptop} {
    padding: 3rem;
  }
  @media ${device.tablet} {
    padding: 2rem;
  }
  @media ${device.mobileL} {
    padding: 0.8rem;
  }
`;

export const Group = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  flex-direction: column;
  margin-bottom: 2rem;

  margin-top: 2rem;
`;

export const GroupHeader = styled.h4`
  font-size: 2.2rem;
  font-weight: 400;
  color: ${props => props.theme.colorPrimaryDark};
  margin-bottom: 1rem;

  text-shadow: ${props => props.theme.shadowLight};
`;

export const Label = styled.label`
  display: flex;
  align-items: center;

  width: 100%;
  color: inherit;

  font-size: 1.8rem;
  color: ${props => props.theme.colorPrimaryDark};
`;

export const Input = styled.input`
  padding: 0.8rem 1.5rem;
  border: ${props =>
    !props.error
      ? `1px solid ${props.theme.colorPrimaryLight}`
      : `1px solid ${props.theme.colorError}`};
  border-radius: 3rem;
  box-shadow: ${props => props.theme.shadowLight};
  width: 100%;
  margin-bottom: 0.5rem;

  &:focus {
    outline: none;
  }
`;

export const Textarea = styled.textarea`
  width: 100%;

  border: ${props =>
    !props.error
      ? `1px solid ${props.theme.colorPrimaryLight}`
      : `1px solid ${props.theme.colorError}`};
  border-radius: 6px;

  box-shadow: ${props => props.theme.shadowLight};

  padding: 1rem;

  font-family: inherit;
  font-size: inherit;

  &:focus {
    outline: none;
  }
`;

export const RadioInputWrapper = styled.span`
  display: flex;
  margin: 0.8rem 0;
  cursor: pointer;
`;

export const RadioInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;

  cursor: pointer;
`;

export const RadioInputControl = styled.span`
  display: block;
  height: 2.4rem;
  width: 2.4rem;

  border-radius: 50%;
  border: ${props =>
    !props.error
      ? `3px solid ${props.theme.colorPrimaryLight}`
      : `3px solid ${props.theme.colorError}`};

  position: relative;

  ${RadioInput} + &::before {
    content: '';
    width: 1.3rem;
    height: 1.3rem;

    box-shadow: inset 1.3rem 1.3rem ${props => props.theme.colorPrimaryLight};
    border-radius: 50%;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);

    transition: all 0.2s ease-in-out;
  }

  ${RadioInput}:checked + &::before {
    transform: translate(-50%, -50%) scale(1);
  }
`;

export const RadioSpan = styled.span`
  margin-left: 1rem;
  line-height: 1;
  cursor: pointer;

  color: ${props => props.theme.colorDarkGray3};
`;

export const DropzoneWrapper = styled.div`
  height: 10rem;
  width: 100%;

  background-color: ${props => props.theme.colorLightGray3};
  border: ${props =>
    !props.error
      ? `2px dotted ${props.theme.colorPrimaryLight}`
      : `2px dotted ${props.theme.colorError}`};
  border-radius: 5px;

  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: column;
  padding: 1rem;

  &:focus {
    border: 2px solid ${props => props.theme.colorPrimary};
    outline: none;
  }
`;

export const DropzoneInput = styled.input`
  position: relative;

  flex: 1;
  height: 10rem;

  &:focus {
    border: 2px solid ${props => props.theme.colorPrimary};
    outline: none;
  }
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  color: ${props => props.theme.colorLightGray1};

  margin: 2rem;
`;
///REVIEWS////
export const Rating = styled.div`
  width: 100%;

  margin-top: 1rem;
`;

export const StarInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;

  cursor: pointer;
`;

export const StarLabel = styled.label`
  margin-right: 2px;
  font-size: 1.8rem;

  cursor: pointer;

  transition: all 0.2s;

  &:hover > svg,
  &:focus > svg {
    fill: ${props => props.theme.colorPrimary};
    font-size: 2rem;
  }

  &:active > svg {
    transform: translateY(2px);
  }
`;

export const Error = styled.span`
  display: block;
  width: 100%;

  margin-bottom: 1.5rem;

  color: ${props => props.theme.colorError};
`;
