import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import Hero from './Hero';
import WhoWeAre from './WhoWeAre';
import Featured from './Featured';
import CTA from './CTA';

import * as Land from '../../styles/components/Landing';
import { redirectTo } from '../../actions';

function Index(props) {
  useEffect(() => {
    props.redirectTo(false);
  });

  return (
    <Land.Container>
      <Hero />
      <WhoWeAre />
      <Featured />
      <CTA />
    </Land.Container>
  );
}

export default connect(
  null,
  { redirectTo }
)(Index);
