import { FETCH_REVIEWS, CLEAR_REVIEWS } from '../actions/types';

const reviewsReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_REVIEWS:
      return { ...action.payload };
    case CLEAR_REVIEWS:
      return { ...action.payload };
    default:
      return state;
  }
};

export default reviewsReducer;
