import styled from 'styled-components';
import { device } from '../../devices';

export const Container = styled.div`
  background-color: ${props => props.theme.colorLightGray3};
`;

export const Section = styled.section`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin: 0 auto;

  color: ${props => props.theme.colorLightGray1};

  padding: 6rem;
  width: 70%;

  @media ${device.tablet} {
    width: 80%;
    padding: 3rem;
  }
  @media ${device.tabletS} {
    width: 85%;
  }
  @media ${device.mobileL} {
    width: 90%;
    padding: 1rem;
  }
`;

export const ImgWrapper = styled.div`
  overflow: hidden;
  height: 60vh;
  width: 100%;

  background-image: url(${props => props.src});
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;

  border-radius: 5px;

  box-shadow: ${props => props.theme.shadowDark};

  @media ${device.tablet} {
    height: 40vh;
  }
`;

export const Description = styled.p`
  margin: 5rem 0;

  font-size: 1.7rem;

  color: ${props => props.theme.colorDarkGray3};
`;

export const Line = styled.div`
  margin: 2rem auto;
  width: 95%;
  border-bottom: 1px solid ${props => props.theme.colorPrimaryLight};

  @media ${device.tablet} {
    margin: 1rem auto;
  }
`;
