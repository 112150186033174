import styled from 'styled-components';
import { device } from '../../devices';

export const Container = styled.div`
  margin-top: 8rem;
  padding: 3rem 0;

  background-color: ${props => props.theme.colorLightGray3};
`;

export const Main = styled.main`
  margin: 0 auto;
  width: 90%;

  padding: 2rem 0;

  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: ${props => props.theme.colorLightGray1};
  @media ${device.mobileM} {
    padding: 1rem 0;
    width: 95%;
  }
`;

export const Card = styled.div`
  width: 85%;

  box-shadow: ${props => props.theme.shadowLight};
  border-radius: 4px;

  margin-bottom: 4rem;
  padding: 2rem;

  display: flex;
  justify-content: center;

  @media ${device.tabletS} {
    flex-direction: column;
  }

  @media ${device.mobileM} {
    padding: 1rem 0;
    width: 95%;
  }
`;

export const Details = styled.div`
  flex: 0 0 40%;

  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${device.tabletS} {
    margin-bottom: 1rem;
  }
`;

export const DeleteButton = styled.button`
  transition: all 0.3s;
  font-size: 3rem;

  background-color: inherit;
  border: none;

  color: ${props => props.theme.colorError};

  &:hover,
  &:focus {
    filter: brightness(110%);
    transform: scale(1.1);
  }

  &:active {
    transform: translateY(2px);
    filter: brightness(90%);
  }
`;

export const DetailItem = styled.span`
  font-size: 1.8rem;

  @media ${device.tabletS} {
    margin-bottom: 1rem;
  }
`;

export const DetailItemLabel = styled.span`
  font-size: 2rem;

  color: ${props => props.theme.colorPrimaryDark};
`;
