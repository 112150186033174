import React from 'react';
import { BsArrowRightShort } from 'react-icons/bs';
import { IoIosArrowDown } from 'react-icons/io';

import * as Hero from '../../styles/components/Landing/Hero';
import { Button, ShopIcon } from '../../styles/components/Reusable';

export default function Index() {
  return (
    <Hero.Container>
      <Hero.Content>
        <Hero.Main>
          <Hero.Title>Teton Wilderness Supply</Hero.Title>
          <Hero.Description>
            <span>Expert</span> Outdoor <span>Gear</span>.
          </Hero.Description>
        </Hero.Main>
        <Button primary='true' to='/store'>
          Shop now{' '}
          <ShopIcon>
            <BsArrowRightShort />
          </ShopIcon>
        </Button>
        <Hero.DownArrow href='#why-us'>
          <IoIosArrowDown />
        </Hero.DownArrow>
      </Hero.Content>
    </Hero.Container>
  );
}
