import axios from 'axios';

export const deleteProduct = async id => {
  try {
    return await axios.delete(`/api/v1/products/${id}`);
  } catch (err) {
    if (err) throw new Error(`Failed to delete Product: ${err}`);
  }
};

export const deleteReview = async id => {
  try {
    return await axios.delete(`/api/v1/reviews/${id}/admin`);
  } catch (err) {
    if (err) throw new Error(err);
  }
};

export const fetchProduct = async id => {
  try {
    return await axios.get(`/api/v1/products/${id}`);
  } catch (err) {
    if (err) throw new Error(`Could not find product: ${err}`);
  }
};
