import styled from 'styled-components';
import { ButtonSecondary } from '../Reusable';
import { device } from '../../devices';

export const Container = styled.div`
  min-height: 55vh;
  padding: 3rem 0;

  background-color: ${props => props.theme.colorLightGray3};
`;

export const CardWrapper = styled.div`
  display: flex;
  justify-content: space-around;

  margin-bottom: 6rem;

  @media ${device.tablet} {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const Card = styled.div`
  color: ${props => props.theme.colorLightGray1};
  flex: 0 0 25%;
  height: 35rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(${props => props.src});
  transition: all 0.3s;
  box-shadow: ${props => props.theme.shadowDark};
  border-radius: 0.5rem;

  &:hover {
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
      url(${props => props.src});
    color: ${props => props.theme.colorPrimaryLight};
  }

  @media ${device.laptop} {
    flex: 0 0 30%;
  }
  @media ${device.tablet} {
    flex: 0 0 40rem;
    width: 60%;
    margin-top: 3rem;
  }
  @media ${device.tabletS} {
    width: 75%;
  }
  @media ${device.mobileL} {
    width: 80%;
  }
  @media ${device.mobileS} {
    width: 90%;
  }
`;

export const Title = styled.h3`
  text-align: center;
  font-size: 3.6rem;
  font-weight: 300;
`;

export const Button = styled(ButtonSecondary)`
  align-self: center;
  transition: all 0.3s;

  ${Card}:hover & {
    color: ${props => props.theme.colorPrimaryLight};
    border: 1px solid ${props => props.theme.colorPrimaryLight};
  }
`;
