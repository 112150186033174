import styled from 'styled-components';
import { device } from '../../devices';

export const Container = styled.div`
  min-height: 60rem;
  height: 100vh;
  box-shadow: 0 2rem 5rem rgba(0, 0, 0, 0.06);
`;

export const Content = styled.div`
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url('/img/heroImage.jpg');
  background-attachment: fixed;
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  color: ${(props) => props.theme.colorLightGray1};

  @media ${device.tabletL} {
    background-attachment: scroll;
  }
`;

export const Main = styled.div`
  flex: 0 0 65%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-top: 9.5rem;
  margin-bottom: -5rem;

  @media ${device.tabletS} {
    margin: 2rem 0 -1rem 0;
  }
`;

export const Title = styled.h1`
  font-size: 6.5rem;
  font-weight: ${(props) => props.theme.lightFontWeight};
  letter-spacing: 0.2rem;
  text-align: center;
`;

export const Description = styled.p`
  font-size: 2.4rem;

  & > span {
    color: ${(props) => props.theme.colorPrimaryLight};
  }
`;

export const DownArrow = styled.a`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  margin-bottom: 4rem;

  transition: all 0.2s;

  &:link,
  &:visited {
    text-decoration: none;
    color: inherit;
    font-size: 5rem;
  }

  & > svg:hover {
    color: ${(props) => props.theme.colorPrimaryDark};
    transform: translateY(-2px);
  }

  &:active {
    transform: translateY(3px);
  }
`;
