import { CLEAR_PRODUCTS, FETCH_PRODUCTS } from '../actions/types';

const products = (state = {}, action) => {
  switch (action.type) {
    case FETCH_PRODUCTS:
      return { ...action.payload };
    case CLEAR_PRODUCTS:
      return { ...action.payload };
    default:
      return state;
  }
};

export default products;
