import { LOGIN_USER, FETCH_USER } from '../actions/types';

const auth = (state = false, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return action.payload || false;

    case FETCH_USER:
      return action.payload || false;
    default:
      return state;
  }
};

export default auth;
