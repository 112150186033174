import React from 'react';
import { connect } from 'react-redux';

import * as Suc from '../styles/components/NotFoundStyles';
import { Button, HeaderSecondary } from '../styles/components/Reusable';

import { BsQuestionSquare } from 'react-icons/bs';
import { scrollToTop } from '../helpers';

export const Success = (props) => {
  return (
    <Suc.Container>
      <Suc.Main>
        <Suc.SuccessImg>
          <BsQuestionSquare />
        </Suc.SuccessImg>
        <HeaderSecondary>Page not found</HeaderSecondary>
        <Suc.Details>
          Unfortunately, we couldn't find the page you're looking for. If you
          need help, email support at{' '}
          <Suc.Highlight>support@tetonwildernesssupply.com</Suc.Highlight>
        </Suc.Details>

        <Button onClick={scrollToTop} to='/' style={{ color: '#fff' }}>
          Go back to home page
        </Button>
      </Suc.Main>
    </Suc.Container>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Success);
