import styled from 'styled-components';
import { device } from '../../devices';

export const Container = styled.div`
  background-color: ${props => props.theme.colorLightGray3};
`;

export const Options = styled.div`
  color: ${props => props.theme.colorPrimary};
  font-size: 2.6rem;

  margin: 3rem 5rem;
  display: flex;
  justify-content: space-between;

  @media ${device.mobileM} {
    font-size: 2.4rem;
    margin: 1.5rem 2.5rem;
  }
`;

export const DropdownWrapper = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const SortLabel = styled.label`
  margin-bottom: 0.5rem;
`;

export const SortWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 1.6rem;
`;

export const SortDropdown = styled.select`
  appearance: none;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  padding-right: 3rem;
  padding-left: 1.5rem;
  margin-right: -2rem;

  font-family: inherit;

  background-color: ${props => props.theme.colorLightGray1};

  border-radius: 2px;
  border: 1px solid ${props => props.theme.colorPrimaryLight};

  color: ${props => props.theme.colorDarkGray3};

  &:focus {
    outline: none;
    border: 2px solid ${props => props.theme.colorPrimaryDark};
  }
`;

export const DropdownItem = styled.option`
  font-family: inherit;
`;

export const Length = styled.span`
  font-size: 2rem;
  font-weight: 400;

  color: ${props => props.theme.colorPrimaryDark};

  display: block;

  margin-bottom: 3rem;
  margin-left: 5rem;
`;

export const Main = styled.main`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
`;

export const Card = styled.div`
  flex: 0 0 30%;
  padding: 2rem;
  margin-bottom: 3rem;

  display: flex;
  align-items: center;
  flex-direction: column;

  border-radius: 4px;

  background-color: ${props => props.theme.colorLightGray1};
  box-shadow: ${props => props.theme.shadowDark};

  @media ${device.laptop} {
    flex: 0 0 42%;
    margin: 2rem 0;
  }
  @media ${device.tabletL} {
    flex: 0 0 45%;
    margin: 2rem 0;
  }
  @media ${device.tablet} {
    flex: 0 0 65%;
    margin: 2rem;
  }
  @media ${device.tabletS} {
    flex: 0 0 80%;
  }
  @media ${device.mobileL} {
    flex: 0 0 85%;
  }
  @media ${device.mobileS} {
    flex: 0 0 90%;
  }
`;

export const CoverImg = styled.img`
  height: 30rem;
  width: 95%;
  border-radius: 4px;
  box-shadow: ${props => props.theme.shadowLight};

  @media ${device.mobileM} {
    height: 25rem;
  }
`;

export const AverageRating = styled.div`
  align-self: flex-start;
  display: flex;
  line-height: 100%;
  text-align: center;
`;

export const StarWrapper = styled.span`
  font-size: 1.8rem;
  color: ${props => props.theme.colorPrimaryDark};
  margin-right: 0.5rem;
`;

export const DetailWrapper = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-around;
  align-items: center;

  margin: 1rem 0;
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h3`
  flex: 0 0 55%;
  font-size: 2.5rem;
  font-weight: 400;

  color: ${props => props.theme.colorPrimary};

  text-shadow: ${props => props.theme.shadowDark};
`;

export const PriceWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Price = styled.span`
  font-size: 2rem;
  color: ${props => props.theme.colorTertiaryLight};

  text-shadow: ${props => props.theme.shadowLight};

  transition: all 0.2s;

  ${Card}:hover & {
    transform: scale(1.2);
  }
`;

export const SalePrice = styled.span`
  font-size: 1.8rem;
  color: ${props => props.theme.colorTertiaryDark};
  opacity: 0.6;
  text-decoration: line-through;

  text-shadow: ${props => props.theme.shadowLight};
`;

export const ButtonWrapper = styled.div`
  width: 95%;
  color: ${props => props.theme.colorLightGray2};
  min-height: 17%;

  display: flex;
  flex-direction: column;

  justify-content: space-between;
`;

export const Paginate = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 2rem;

  width: 100%;

  padding: 3rem;
`;

export const PageMove = styled.button`
  font-size: inherit;
  background-color: inherit;
  border: none;

  color: ${props => props.theme.colorPrimary};

  padding-top: 1rem;
  padding-left: 1.7rem;
  padding-right: 1.7rem;
  padding-bottom: 1rem;

  transition: all 0.2s;

  &:hover {
    color: ${props => props.theme.colorPrimaryLight};
    font-size: 2.2rem;
  }

  &:active {
    color: ${props => props.theme.colorPrimaryDark};
    font-size: 1.9rem;
    outline: none;
  }

  &:focus {
    outline: none;
  }

  &:not(:first-child) {
    border-left: 1px solid ${props => props.theme.colorPrimary};
  }

  &:not(:last-child) {
    border-right: 1px solid ${props => props.theme.colorPrimary};
  }
`;

export const PageLink = styled.button`
  font-size: inherit;
  background-color: inherit;
  border: none;

  color: ${props => props.theme.colorPrimary};

  padding: 1rem;

  transition: all 0.2s;

  &:hover {
    color: ${props => props.theme.colorPrimaryLight};
    font-size: 2.2rem;
  }

  &:active {
    color: ${props => props.theme.colorPrimaryDark};
    font-size: 1.9rem;
    outline: none;
  }

  &:focus {
    outline: none;
  }
`;
