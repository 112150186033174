import axios from 'axios';

import {
  LOGIN_USER,
  FETCH_USER,
  ACCEPT_COOKIE,
  CHECK_COOKIE,
  REDIRECT_TO,
  FETCH_PRODUCTS,
  FETCH_PRODUCT,
  SET_UPDATE_PRODUCT,
  CLEAR_UPDATE,
  FETCH_CART,
  CLEAR_PRODUCT,
  CLEAR_PRODUCTS,
  FETCH_REVIEWS,
  CLEAR_REVIEWS,
  FETCH_ORDERS,
  DISPLAY_MESSAGE
} from './types.js';

//////////////Auth////////////////
export const loginUser = (reqBody) => async (dispatch) => {
  const res = await axios.post('/api/v1/users/login', reqBody);

  dispatch({ type: LOGIN_USER, payload: res.data });
};

export const signupUser = (reqBody) => async (dispatch) => {
  const res = await axios.post('/api/v1/users/signup', reqBody);

  dispatch({ type: LOGIN_USER, payload: res.data });
};

export const fetchUser = () => async (dispatch) => {
  const res = await axios.post(
    '/api/v1/users/checkLogin',
    {},
    {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json'
      },
      withCredentials: true
    }
  );

  dispatch({ type: FETCH_USER, payload: res.data });
};

///////////COOKIE LEGAL///////////////
export const acceptCookies = () => async (dispatch) => {
  const res = await axios.post(
    '/api/v1/users/acceptCookies',
    {},
    {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json'
      },
      withCredentials: true
    }
  );

  dispatch({ type: ACCEPT_COOKIE, payload: res.data.data });
};

export const checkCookies = () => async (dispatch) => {
  const res = await axios.post(
    '/api/v1/users/checkCookies',
    {},
    {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json'
      },
      withCredentials: true
    }
  );

  dispatch({ type: CHECK_COOKIE, payload: res.data.data });
};

///////////////Utils/////////////
export const redirectTo = (to) => {
  return {
    type: REDIRECT_TO,
    payload: to
  };
};

export const displayMessage = (msg = '', time = 0, error = false) => {
  return { type: DISPLAY_MESSAGE, payload: { message: msg, time, error } };
};

//////////////Mutiple Products/////////////
export const fetchProducts = (type, page, sort) => async (dispatch) => {
  const res = await axios.get(
    `/api/v1/products?type=${type}&page=${page || 1}&sort=${
      sort || '-createdAt'
    }`
  );

  dispatch({ type: FETCH_PRODUCTS, payload: res.data });
};

export const clearProducts = () => {
  return { type: CLEAR_PRODUCTS, payload: {} };
};

////////////Single Product//////////////
export const fetchProduct = (id) => async (dispatch) => {
  const res = await axios.get(`/api/v1/products/${id}`);

  dispatch({ type: FETCH_PRODUCT, payload: res.data.data.product });
};

export const clearProduct = () => {
  return { type: CLEAR_PRODUCT, payload: {} };
};

export const setUpdateProduct = (id) => async (dispatch) => {
  const res = await axios.get(`/api/v1/products/${id}`);

  dispatch({ type: SET_UPDATE_PRODUCT, payload: res.data.data.product });
};

export const clearUpdate = () => {
  return { type: CLEAR_UPDATE, payload: {} };
};

////////////Reviews/////////////
export const fetchReviews = (id, page) => async (dispatch) => {
  const res = await axios.get(
    `/api/v1/products/${id}/reviews?page=${page}&limit=5&sort=-createdAt`
  );

  dispatch({ type: FETCH_REVIEWS, payload: res.data });
};

export const deleteReview = (id) => async (dispatch) => {
  try {
    await axios.delete(`/api/v1/reviews/${id}/admin`);
  } catch (err) {
    dispatch({
      type: DISPLAY_MESSAGE,
      payload: {
        message: 'Cannot Delete Review (Unauthorized)',
        time: 5,
        error: true
      }
    });
  }

  const res = await axios.get(
    `/api/v1/products/${id}/reviews?page=1&limit=5&sort=-createdAt`
  );

  dispatch({ type: FETCH_REVIEWS, payload: res.data });
};

export const clearReviews = () => {
  return { type: CLEAR_REVIEWS, payload: {} };
};

/////////////Cart////////////////
export const addToCart = (id, amount, color) => async (dispatch) => {
  const res = await axios.post(
    `/api/v1/cart/add-to-cart`,
    {
      productId: id,
      amount: amount * 1,
      color
    },
    {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json'
      },
      withCredentials: true
    }
  );

  dispatch({ type: FETCH_CART, payload: res.data });
};

export const fetchCart = () => async (dispatch) => {
  const res = await axios.get(`/api/v1/cart/get-cart`, {
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  });

  dispatch({ type: FETCH_CART, payload: res.data });
};

export const reduceItemCart = (id, color) => async (dispatch) => {
  const res = await axios.patch(
    `/api/v1/cart/reduce-item`,
    { productId: id, color },
    {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json'
      },
      withCredentials: true
    }
  );

  dispatch({ type: FETCH_CART, payload: res.data });
};

export const removeItemCart = (productId, color) => async (dispatch) => {
  const jsonOptions = JSON.stringify({ productId: productId, color });

  const res = await axios.delete(`/api/v1/cart/remove-item`, {
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json'
    },
    withCredentials: true,
    data: jsonOptions
  });

  dispatch({ type: FETCH_CART, payload: res.data });
};

export const clearCart = () => async (dispatch) => {
  await axios.delete(`/api/v1/cart/clear-cart`, {
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  });

  dispatch({ type: FETCH_CART, payload: {} });
};

////////////Orders//////////////
export const fetchOrders = (page) => async (dispatch) => {
  const res = await axios.get(`/api/v1/orders?page=${page || 1}&limit=20`, {
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  });

  dispatch({ type: FETCH_ORDERS, payload: res.data });
};

export const deleteOrder = (id, page) => async (dispatch) => {
  await axios.delete(
    `/api/v1/orders/${id}`,
    {},
    {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json'
      },
      withCredentials: true
    }
  );
  const res = await axios.get(`/api/v1/orders?page=${page}&limit=20`, {
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  });

  dispatch({ type: FETCH_ORDERS, payload: res.data });
};
