import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { device } from '../devices';

///////////LAYOUT//////////
export const Heading = styled.div`
  height: 75vh;

  display: flex;
  justify-content: center;
  align-items: center;

  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url(${props => props.src});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: ${props => props.position};
  background-attachment: fixed;

  @media ${device.tabletL} {
    background-attachment: scroll;
  }
`;

/////////TYPOGRAPHY///////////
export const HeaderPrimary = styled.h1`
  text-align: center;
  margin: 0 auto;

  font-size: 6.5rem;
  font-weight: 300;

  letter-spacing: 2px;

  color: ${props => props.theme.colorLightGray1};

  text-shadow: ${props => props.theme.shadowDark};
`;

export const HeaderSecondary = styled.h2`
  text-align: center;
  margin-bottom: ${props => props.marBot || '4rem'};

  font-size: 4.5rem;
  font-weight: ${props => props.theme.lightFontWeight};
  letter-spacing: 1px;

  text-shadow: ${props => props.theme.shadowDark};

  color: ${props =>
    props.light ? props.theme.colorPrimary : props.theme.colorPrimaryDark};

  @supports ((-webkit-background-clip: text) or (background-clip: text)) {
    color: transparent;

    background-image: linear-gradient(
      to right,
      ${props => props.theme.colorPrimaryLight},
      ${props => props.theme.colorPrimaryDark}
    );
    -webkit-background-clip: text;
    background-clip: text;
  }
`;

export const HeaderTertiary = styled.h3`
  font-size: 2.5rem;
  color: ${props => props.theme.colorPrimary};

  font-weight: 400;
  margin: 1rem 0;
  min-width: 15rem;

  border-bottom: 1px solid ${props => props.theme.colorPrimary};
`;

//////////BUTTONS////////////////
export const Button = styled(Link)`
  color: ${props => props.theme.colorLightGray1};
  border-radius: 0.5rem;
  padding: 1rem;
  font-size: ${props => (props.primary ? '3rem' : '2rem')};
  letter-spacing: 0.1rem;
  width: ${props => props.width};
  display: block;
  text-decoration: none;
  text-align: center;
  margin-bottom: 0.8rem;
  backface-visibility: hidden;

  background-color: ${props =>
    props.secondary ? props.theme.colorTertiaryDark : props.theme.colorPrimary};
  background-image: linear-gradient(
    to right,
    ${props => props.theme.colorPrimaryLight},
    ${props => props.theme.colorPrimaryDark}
  );

  box-shadow: ${props => props.theme.shadowLight};

  transition: all 0.3s;

  &:hover {
    transform: scale(1.05);
    background-color: ${props =>
      props.secondary
        ? props.theme.colorTertiaryLight
        : props.theme.colorPrimaryLight};
  }

  &:active {
    transform: scale(1.05) translateY(2px);
  }
`;

export const ButtonSubmit = styled.input`
  color: inherit;
  border: 1px solid ${props => props.theme.colorPrimaryLight};
  border-radius: 5px;
  padding: 1rem;
  font-size: ${props => (props.primary ? '3rem' : '2rem')};
  letter-spacing: 0.1rem;
  width: ${props => props.width};
  display: block;
  text-decoration: none;
  text-align: center;

  cursor: ${props => (props.disabled ? 'default' : 'pointer')};

  box-shadow: ${props => props.theme.shadowLight};

  background-color: ${props =>
    props.secondary ? props.theme.colorTertiaryDark : props.theme.colorPrimary};
  background-image: linear-gradient(
    to right,
    ${props => props.theme.colorPrimaryLight},
    ${props => props.theme.colorPrimaryDark}
  );

  transition: all 0.3s;
  opacity: ${props => (props.disabled ? '.6' : '1')};

  &:hover {
    transform: ${props => (props.disabled ? 'scale(1)' : 'scale(1.05)')};
  }

  &:active {
    transform: scale(1.05) translateY(2px);
  }

  &:focus {
    outline: none;
  }
`;

export const ButtonSecondary = styled(Link)`
  border: 1px solid ${props => props.theme.colorLightGray1};
  color: ${props => props.theme.colorLightGray1};

  padding: 1rem 2rem;

  display: block;
  text-decoration: none;
  text-align: center;

  &:hover,
  &:focus {
    color: ${props => props.theme.colorPrimaryLight};
    border: 1px solid ${props => props.theme.colorPrimaryLight};
  }

  &:active {
    transform: scale(1.1);
  }

  &:focus {
    outline: none;
  }
`;

export const ShopIcon = styled.div`
  display: inline-block;

  & > svg {
    transition: all 0.3s;
    margin: -0.5rem auto;

    ${Button}:hover & {
      transform: translateX(1rem);
    }
  }
`;

export const ButtonTextLink = styled(Link)`
  margin: 1rem;
  font-size: 1.8;
  font-family: inherit;
  position: relative;

  border-bottom: 2px solid ${props => props.theme.colorPrimaryLight};
  transition: all 0.3s;

  &:link,
  &:visited {
    color: ${props => props.theme.colorPrimary};
    text-decoration: none;
  }

  & > svg {
    font-size: 2rem;

    position: absolute;
    right: -20%;
    top: 20%;
  }

  &:hover {
    transform: scale(1.05);
    filter: brightness(110%);
  }

  &:active {
    transform: translateY(2px);
    filter: brightness(95%);
  }
`;

//////ADD TO CART///////////
export const AddToCart = styled.div`
  display: flex;
  width: 100%;
`;

export const AddButton = styled(Link)`
  width: 75%;
  display: block;

  line-height: 1;
  text-align: center;

  font-size: 2rem;

  box-shadow: ${props => props.theme.shadowLight};

  background-image: ${props =>
    props.secondary
      ? `linear-gradient(to right, ${props.theme.colorTertiaryLight}, ${
          props.theme.colorTertiaryDark
        })`
      : `linear-gradient(
    to right,
    ${props.theme.colorPrimaryLight},
    ${props.theme.colorPrimaryDark}
  )`};
  background-repeat: no-repeat;
  border-radius: 5px;

  padding: 1rem;

  display: flex;
  align-items: center;
  justify-content: center;

  transition: all 0.2s;

  opacity: ${props => (props.disabled ? 0.7 : 1)};

  cursor: ${props => (props.disabled ? 'default' : 'pointer')};

  &:link,
  &:visited {
    color: ${props => props.theme.colorLightGray1};
    text-decoration: none;
  }

  &:hover {
    transform: ${props => (props.disabled ? 'scale(1)' : 'scale(1.05)')};
  }

  &:active {
    transform: ${props => (props.disabled ? '' : 'translateY(2px)')};
  }

  & > svg {
    margin-right: 1rem;

    font-size: 2.4rem;
  }
`;

export const AddAmount = styled.input`
  width: 25%;
  display: block;

  font-size: 1.8rem;

  box-shadow: ${props => props.theme.shadowLight};

  text-align: center;
  background-color: ${props => props.theme.colorLightGray1};
  color: ${props => props.theme.colorDarkGray3};

  border: 2px solid ${props => props.theme.colorDarkGray2};
  border-radius: 5px;

  transition: all 0.2s;

  &:hover {
    transform: scale(1.05);
  }

  &:focus {
    border: 2px solid ${props => props.theme.colorPrimary};
    color: ${props => props.theme.colorPrimaryDark};

    outline: none;
  }
`;
////////BREADCRUMB////////////////
export const BreadcrumbLink = styled(Link)`
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  display: inline-block;

  transition: all 0.2s;

  &:link,
  &:visited {
    color: ${props => props.theme.colorPrimary};
    text-decoration: none;

    &:hover {
      color: ${props => props.theme.colorPrimaryDark};
      transform: scale(1.1);
    }

    &:active {
      transform: scaleX(1);
    }
  }
`;

//////PAGINATE/////////
export const Paginate = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 2rem;

  width: 100%;

  padding: 3rem;
`;

export const PageMove = styled.button`
  font-size: inherit;
  background-color: inherit;
  border: none;

  color: ${props => props.theme.colorPrimary};

  padding-top: 1rem;
  padding-left: 1.7rem;
  padding-right: 1.7rem;
  padding-bottom: 1rem;

  transition: all 0.2s;

  &:hover {
    color: ${props => props.theme.colorPrimaryLight};
    font-size: 2.2rem;
  }

  &:active {
    color: ${props => props.theme.colorPrimaryDark};
    font-size: 1.9rem;
    outline: none;
  }

  &:focus {
    outline: none;
  }

  &:not(:first-child) {
    border-left: 1px solid ${props => props.theme.colorPrimary};
  }

  &:not(:last-child) {
    border-right: 1px solid ${props => props.theme.colorPrimary};
  }
`;

export const PageLink = styled.button`
  font-size: inherit;
  background-color: inherit;
  border: none;

  color: ${props => props.theme.colorPrimary};

  padding: 1rem;

  transition: all 0.2s;

  &:hover {
    color: ${props => props.theme.colorPrimaryLight};
    font-size: 2.2rem;
  }

  &:active {
    color: ${props => props.theme.colorPrimaryDark};
    font-size: 1.9rem;
    outline: none;
  }

  &:focus {
    outline: none;
  }
`;

////////Flash Message///////////
export const Flash = styled.div`
  position: fixed;
  left: 50%;

  transform: translateX(-50%);

  background-color: ${props =>
    props.error ? props.theme.colorError : props.theme.colorPrimary};
  opacity: ${props => (props.hide ? '0' : '.8')};
  z-index: 10;

  min-height: 7rem;
  width: 80%;

  border-radius: 1rem;

  display: flex;
  justify-content: center;

  font-size: 2rem;
  color: ${props => props.theme.colorLightGray1};

  box-shadow: ${props => props.theme.shadowLight};

  text-align: center;
  line-height: 7rem;

  transition: all 0.3s;
`;

export const FlashClose = styled.button`
  position: absolute;
  right: 0;

  font-size: 2rem;

  border: none;
  color: inherit;

  box-shadow: ${props => props.theme.shadowVeryLight};

  margin-right: 2rem;
  margin-top: 1rem;

  height: 2rem;
  width: 2rem;

  background-color: inherit;
  cursor: pointer;

  transition: all 0.2s;
  &:hover,
  &:focus {
    transform: translateY(-2px);
    outline: none;
  }

  &:active {
    transform: translateY(1px);
  }
`;
