import styled from 'styled-components';
import { BsStar, BsStarFill } from 'react-icons/bs';
import { device } from '../../../devices';

export const Container = styled.div`
  width: 95%;
  margin: 0 auto;

  background-color: ${props => props.theme.colorLightGray1};

  box-shadow: ${props => props.theme.shadowLight};
`;

export const Main = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
`;

export const Results = styled.span`
  font-size: 2rem;

  color: ${props => props.theme.colorPrimary};
`;

export const Review = styled.div`
  position: relative;

  width: 95%;
  display: flex;

  flex-direction: column;
  margin: 1.5rem;
  padding: 1.5rem;

  border: 1px solid ${props => props.theme.colorPrimaryLight};
  border-radius: 5px;

  box-shadow: ${props => props.theme.shadowLight};
`;

export const Head = styled.div`
  display: flex;

  justify-content: space-between;
`;

export const Person = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  border-bottom: 1px solid ${props => props.theme.colorPrimaryLight};
  margin-bottom: 1rem;

  & > svg {
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;

    filter: drop-shadow(${props => props.theme.shadowVeryLight});
    color: ${props => props.theme.colorDarkGray4};

    font-size: 2.8rem;
  }
`;

export const Name = styled.span`
  font-size: 1.8rem;
  color: ${props => props.theme.colorPrimary};
`;

export const Date = styled.span`
  font-size: 1.5rem;
  font-style: italic;

  color: ${props => props.theme.colorDarkGray1};
`;

export const Stars = styled.div`
  border-bottom: 1px solid ${props => props.theme.colorPrimaryLight};
  margin-bottom: 1rem;

  font-size: 1.7rem;

  & > svg {
    margin-right: 1px;
  }

  @media ${device.mobileM} {
    font-size: 1.5rem;
  }
`;

export const StarFilled = styled(BsStarFill)`
  color: ${props => props.theme.colorPrimaryDark};
`;

export const StarEmpty = styled(BsStar)`
  color: ${props => props.theme.colorPrimaryLight};
`;

export const Body = styled.p``;

export const Delete = styled.div`
  transition: all 0.2s;

  cursor: pointer;

  position: absolute;
  right: 1rem;
  bottom: 0;

  display: inline-block;

  & > svg {
    font-size: 3rem;

    color: ${props => props.theme.colorError};
  }

  &:hover {
    transform: scale(1.1);
  }
  &:active {
    transform: translateY(2px);
  }
`;

export const Form = styled.form`
  padding: 3rem 0;

  width: 50%;
  margin: 0 auto;
  margin-top: 3rem;

  border-top: 1px solid ${props => props.theme.colorPrimaryLight};

  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  @media ${device.tabletL} {
    width: 60%;
  }
  @media ${device.tabletS} {
    width: 70%;
  }
  @media ${device.mobileL} {
    width: 75%;
  }
  @media ${device.mobileS} {
    width: 80%;
  }
`;
