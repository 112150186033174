import { ACCEPT_COOKIE, CHECK_COOKIE } from '../actions/types';

const cookieReducer = (state = false, action) => {
  switch (action.type) {
    case ACCEPT_COOKIE:
      return action.payload || false;
    case CHECK_COOKIE:
      return action.payload || false;
    default:
      return state;
  }
};

export default cookieReducer;
