import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { BsTrash } from 'react-icons/bs';

import * as Order from '../../styles/components/Orders';
import { fetchOrders, deleteOrder } from '../../actions';
import {
  HeaderSecondary,
  Paginate,
  PageMove,
  PageLink,
  HeaderTertiary
} from '../../styles/components/Reusable';

export const Index = (props) => {
  const [page, setPage] = useState(1);
  const { fetchOrders, deleteOrder, orders } = props;

  useEffect(() => {
    fetchOrders(page);
  }, [page, fetchOrders]);

  const renderPaginate = () => {
    const renderLinks = () => {
      const pages = Math.ceil(orders.totalResults / 20);
      let links = [];
      for (let i = 1; i <= pages; i++) {
        links.push(
          <PageLink key={`page ${i}`} onClick={() => setPage(i)}>
            {i}
          </PageLink>
        );
      }
      return links;
    };
    return (
      <Paginate>
        <PageMove onClick={() => (page > 1 ? setPage(page - 1) : null)}>
          Prev
        </PageMove>
        {renderLinks()}
        <PageMove
          onClick={() =>
            page < orders.totalResults / 20 ? setPage(page + 1) : null
          }
        >
          Next
        </PageMove>
      </Paginate>
    );
  };

  const renderOrders = () => {
    if (!orders || !orders.data || !orders.data.orders) {
      return <HeaderTertiary>No orders found.</HeaderTertiary>;
    }
    return orders.data.orders.map((order) => {
      const { productDescsArr } = order;
      const date = new Date(order.createdAt);
      const renderProductDescs = () => {
        return productDescsArr.map((desc, i) => {
          return <Order.DetailItem key={i}>{desc}</Order.DetailItem>;
        });
      };

      return (
        <Order.Card key={order._id}>
          <Order.Details>
            <Order.DetailItemLabel>Payment Status:</Order.DetailItemLabel>
            <Order.DetailItem>
              {order.paymentSucceeded ? 'Success' : ''}
              {order.paymentFailed ? 'Pending' : ''}
              {!order.paymentSucceeded && order.paymentFailed ? 'Failed' : ''}
            </Order.DetailItem>
            <Order.DetailItemLabel>Order Received:</Order.DetailItemLabel>
            <Order.DetailItem>{date.toDateString()}</Order.DetailItem>
            <Order.DetailItemLabel>Order Total Cost</Order.DetailItemLabel>
            <Order.DetailItem>${order.finalPrice}</Order.DetailItem>
            <Order.DetailItemLabel>Product Details</Order.DetailItemLabel>
            {renderProductDescs()}
            <Order.DetailItem>
              Total Quantity: {order.totalQty}
            </Order.DetailItem>
          </Order.Details>
          <Order.Details>
            <Order.DetailItemLabel>Customer Info</Order.DetailItemLabel>
            <Order.DetailItem>
              Name: {order.firstName} {order.lastName}
            </Order.DetailItem>
            <Order.DetailItem>Email: {order.email}</Order.DetailItem>
            <Order.DetailItem>Phone: {order.phone}</Order.DetailItem>
            <Order.DetailItem>Street Address: {order.street}</Order.DetailItem>
            {order.streetNumber ? (
              <Order.DetailItem>
                Apartment/Unit #: {order.streetNumber}
              </Order.DetailItem>
            ) : (
              ''
            )}
            <Order.DetailItem>City: {order.city}</Order.DetailItem>
            <Order.DetailItem>State: {order.state}</Order.DetailItem>
            <Order.DetailItem>Zip Code: {order.zip}</Order.DetailItem>
          </Order.Details>
          <Order.DeleteButton
            onClick={() => {
              deleteOrder(order._id, page);
            }}
          >
            <BsTrash />
          </Order.DeleteButton>
        </Order.Card>
      );
    });
  };

  return (
    <Order.Container>
      <Order.Main>
        <HeaderSecondary>Orders</HeaderSecondary>
        {renderOrders()}
      </Order.Main>
      {renderPaginate()}
    </Order.Container>
  );
};

const mapStateToProps = (state) => ({ orders: state.orders });

const mapDispatchToProps = { fetchOrders, deleteOrder };

export default connect(mapStateToProps, mapDispatchToProps)(Index);
