import React from 'react';
import { connect } from 'react-redux';

import * as Suc from '../../../styles/components/Checkout/Success';
import { Button, HeaderSecondary } from '../../../styles/components/Reusable';

import { IoCheckmarkDoneCircleOutline } from 'react-icons/io5';
import { scrollToTop } from '../../../helpers';

export const Success = (props) => {
  return (
    <Suc.Container>
      <Suc.Main>
        <Suc.SuccessImg>
          <IoCheckmarkDoneCircleOutline />
        </Suc.SuccessImg>
        <HeaderSecondary>Purchase Successful</HeaderSecondary>
        <Suc.Details>
          A receipt has been sent to the email associated with your purchase! If
          you have any questions or concerns, please contact us at{' '}
          <Suc.Highlight>(208)406-6977</Suc.Highlight> or email us at{' '}
          <Suc.Highlight>support@tetonwilderness.com</Suc.Highlight> .
        </Suc.Details>

        <Button onClick={scrollToTop} to='/' style={{ color: '#fff' }}>
          Go to home page
        </Button>
      </Suc.Main>
    </Suc.Container>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Success);
