import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Media from 'react-media';
import { connect } from 'react-redux';
import { scrollToTop } from '../../helpers';
import { RiMenu4Fill, RiCloseFill } from 'react-icons/ri';
import { RiArrowDownSLine } from 'react-icons/ri';
import { FiShoppingCart } from 'react-icons/fi';

//StyledComponents
import * as Nav from '../../styles/components/NavbarStyles';
import { Button } from '../../styles/components/Reusable';
import { device } from '../../styles/devices';
import { fetchUser } from '../../actions';

function Navigation(props) {
  const { fetchUser } = props;
  const [open, setOpen] = useState(false);
  const [changing, setChanging] = useState(false);
  const [expandDropdownOpen, setExpandDropdownOpen] = useState(false);
  const history = useHistory();
  const { pathname } = history.location;

  useEffect(() => {
    setOpen(false);
    setExpandDropdownOpen(false);
    setChanging(false);
    fetchUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, history]);

  const renderAdmin = () => {
    return (
      <>
        <Nav.Admin open={open}>
          <Button
            to='/new-product'
            style={{ color: '#fff' }}
            onClick={() => {
              scrollToTop();
              setOpen(false);
            }}
          >
            Add new Product
          </Button>
        </Nav.Admin>
        <Nav.Admin open={open}>
          <Button
            to='/orders'
            style={{ color: '#fff' }}
            onClick={() => {
              scrollToTop();
              setOpen(false);
            }}
          >
            Customer Orders
          </Button>
        </Nav.Admin>
      </>
    );
  };

  const renderLarge = () => {
    return (
      <>
        <Nav.LeftWrapper>
          <Nav.Logo to='/'>
            <Nav.LogoImg src='/img/logo.svg' alt='TetonWilderness Logo' />
          </Nav.Logo>
          <Nav.DropdownWrapper>
            <Nav.DropdownToggle>
              <Nav.DropdownLink to='/store'>Store</Nav.DropdownLink>
              <Nav.DropdownIcon />
            </Nav.DropdownToggle>
            <Nav.DropdownOptions>
              <Nav.DropdownOptionsWrapper>
                <Nav.DropdownOptionMain to='/rafts'>
                  Rafts
                </Nav.DropdownOptionMain>
                <Nav.DropdownOptionMain to='/kayaks'>
                  Kayaks
                </Nav.DropdownOptionMain>
                <Nav.DropdownOptionMain to='/SUPs'>SUPs</Nav.DropdownOptionMain>
              </Nav.DropdownOptionsWrapper>
              <Nav.DropdownOptionsWrapper>
                <Nav.DropdownHeader>Featured</Nav.DropdownHeader>
                <Nav.DropdownFeaturedWrapper>
                  <Nav.DropdownOption to='/rafts/612594471a17cb001611b8fb'>
                    12' Hoback
                  </Nav.DropdownOption>
                  <Nav.DropdownOption to='#'>CoolKayak2</Nav.DropdownOption>
                  <Nav.DropdownOption to='#'>CoolKayak2</Nav.DropdownOption>
                  <Nav.DropdownOption to='#'>CoolKayak2</Nav.DropdownOption>
                </Nav.DropdownFeaturedWrapper>
              </Nav.DropdownOptionsWrapper>
            </Nav.DropdownOptions>
          </Nav.DropdownWrapper>
          {/* <Nav.AboutUs to='/about-us'>About</Nav.AboutUs> */}
        </Nav.LeftWrapper>
        {/*
        <Nav.Search>
          <Nav.SearchInput placeholder="search our catalog" />
          <Nav.SearchButton>
            <Nav.SearchIcon />
          </Nav.SearchButton>
        </Nav.Search>{' '}
        */}
        <Nav.RightWrapper>
          {props.user ? renderAdmin() : null}
          <Nav.CartWrapper to='/cart'>
            <Nav.CartIcon />
          </Nav.CartWrapper>
        </Nav.RightWrapper>
      </>
    );
  };

  const renderSmall = () => {
    return (
      <>
        <Nav.LeftWrapper>
          {!open ? (
            <Nav.Logo to='/' onClick={() => setOpen(false)}>
              <Nav.LogoImg src='/img/logo.svg' alt='TetonWilderness Logo' />
            </Nav.Logo>
          ) : (
            ''
          )}
        </Nav.LeftWrapper>
        <Nav.RightWrapper>
          <Nav.Expand
            open={open}
            changing={changing}
            onClick={() => {
              setChanging(true);
              setTimeout(() => {
                !open ? setOpen(true) : setOpen(false);
                setChanging(false);
              }, 300);
            }}
          >
            {!open ? <RiMenu4Fill /> : <RiCloseFill />}
          </Nav.Expand>
        </Nav.RightWrapper>
      </>
    );
  };

  const renderMenu = () => {
    return (
      <Nav.ExpandMenu open={open}>
        <Nav.ExpandItem to='/' onClick={() => setOpen(false)}>
          Home
        </Nav.ExpandItem>
        <Nav.ExpandLine />
        {/* <Nav.ExpandItem to='/about-us' onClick={() => setOpen(false)}>
          About Us
        </Nav.ExpandItem> */}
        <Nav.ExpandLine />
        <Nav.ExpandDropdownLink
          onClick={() =>
            expandDropdownOpen
              ? setExpandDropdownOpen(false)
              : setExpandDropdownOpen(true)
          }
        >
          Store
          <RiArrowDownSLine />
        </Nav.ExpandDropdownLink>
        <Nav.ExpandDropdown open={expandDropdownOpen}>
          <Nav.ExpandDropdownItem to='/rafts' onClick={() => setOpen(false)}>
            Rafts
          </Nav.ExpandDropdownItem>
          <Nav.ExpandDropdownItem to='/kayaks' onClick={() => setOpen(false)}>
            Kayaks
          </Nav.ExpandDropdownItem>
          <Nav.ExpandDropdownItem to='/SUPs' onClick={() => setOpen(false)}>
            SUPs
          </Nav.ExpandDropdownItem>
        </Nav.ExpandDropdown>
        <Nav.ExpandLine />
        <Nav.ExpandItem to='/cart' onClick={() => setOpen(false)}>
          Cart
          <FiShoppingCart />
        </Nav.ExpandItem>
        <Nav.ExpandLine />
        {props.user ? renderAdmin() : null}
      </Nav.ExpandMenu>
    );
  };

  return (
    <>
      <Nav.NavWrapper open={open}>
        <Media query={`${device.tablet}`} render={renderSmall} />
        <Media query='(min-width: 770px)' render={renderLarge} />
      </Nav.NavWrapper>
      {open ? renderMenu() : ''}
    </>
  );
}

const mapStateToProps = ({ user }) => {
  return { user };
};

export default connect(mapStateToProps, { fetchUser })(Navigation);
