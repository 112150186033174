import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { device } from '../devices';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  margin-left: -2rem;

  height: 7rem;
  background-color: rgba(${(props) => props.theme.colorDarkGray4RGB}, 0.9);

  position: fixed;
  z-index: 10;

  bottom: 0;

  color: ${(props) => props.theme.colorLightGray1};

  @media ${device.tablet} {
    margin-left: 0;
    padding: 1rem;
  }

  @media ${device.tabletS} {
    height: 10rem;
  }

  @media ${device.mobileL} {
    flex-direction: column;
    height: 18rem;

    justify-content: space-between;

    text-align: center;
  }
`;

export const Text = styled.p`
  font-size: 1.7rem;
`;

export const TextLink = styled(Link)`
  color: ${(props) => props.theme.colorPrimaryLight};
  font-size: 1.8rem;

  text-decoration: none;
`;

export const AcceptButton = styled.button`
  margin-left: 2rem;

  padding: 1rem;
  border-radius: 3px;
  border: 2px solid ${(props) => props.theme.colorLightGray1};

  color: inherit;
  font-size: 1.6rem;

  cursor: pointer;

  background-color: transparent;

  outline: none;
  text-decoration: none;

  @media ${device.tablet} {
    margin-left: 0.8rem;
  }
`;
