const theme = {
  defaultFontSize: '1.6rem',
  defaultFontWeight: '400',
  lightFontWeight: '300',
  boldFontWeight: '700',
  colorPrimaryLight: '#7ed56f',
  colorPrimary: '#55c57a',
  colorPrimaryRGB: '85, 197, 122',
  colorPrimaryDark: '#28b485',
  colorSecondaryDark: '#513018',
  colorSecondaryLight: '#835431',
  colorTertiaryLight: '#0789b5',
  colorTertiaryDark: '#065671',
  colorLightGray1: '#faf9f9',
  colorLightGray2: '#f4f2f2',
  colorLightGray3: '#f0eeee',
  colorLightGray3RGB: '240, 238, 238',
  colorLightGray4: '#ccc',
  colorDarkGray1: '#777',
  colorDarkGray2: '#999',
  colorDarkGray2RGB: '153, 153, 153',
  colorDarkGray3: '#333',
  colorDarkGray4: '#808080',
  colorDarkGray4RGB: '128, 128, 128',
  colorWhite: '#fff',
  colorBlack: '#000',
  colorError: '#f44336',
  borderLight: `1px solid #ccc`,
  shadowLight: '.2rem .2rem 1rem rgba(0,0,0,.3)',
  shadowVeryLight: '.2rem .2rem 1rem rgba(0,0,0,.2)',
  shadowDark: '.1rem .3rem 4rem rgba(0,0,0,.4)'
};

export default theme;
