import React, { useState } from 'react';

import * as Info from '../../../../styles/components/Products/ProductShow/Info';
import { HeaderSecondary } from '../../../../styles/components/Reusable';

export default function Index({ product }) {
  const [selected, setSelected] = useState(0);

  const renderContent = () => {
    if (selected === 0) {
      return <Info.Desc>{product.description}</Info.Desc>;
    } else {
      const specsArr = product.specs[0].split(',');

      const renderSpecTable = () => {
        return specsArr.map((spec, i) => {
          const specHead = spec.split(' ')[0].replace(/-/g, ' ');
          const specData = spec.split(' ')[1].replace(/-/g, ' ');

          return (
            <React.Fragment key={i}>
              <Info.TableHead>{specHead}</Info.TableHead>
              <Info.TableData>{specData}</Info.TableData>
            </React.Fragment>
          );
        });
      };

      return (
        <Info.Specs>
          {product.specs[0] ? (
            <Info.Table>
              <Info.Column>{renderSpecTable()}</Info.Column>
            </Info.Table>
          ) : (
            'No specs yet.'
          )}
        </Info.Specs>
      );
    }
  };

  return (
    <Info.Container>
      <HeaderSecondary>Details</HeaderSecondary>
      <Info.Tab>
        <Info.TabItem
          selected={selected === 0 ? true : false}
          onClick={() => {
            setSelected(0);
          }}
        >
          Description
        </Info.TabItem>
        <Info.TabItem
          selected={selected === 1 ? true : false}
          onClick={() => {
            setSelected(1);
          }}
        >
          Specs
        </Info.TabItem>
      </Info.Tab>
      {renderContent()}
    </Info.Container>
  );
}
