import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

//Base components
import Login from './Login';
import Signup from './SignUp';
import NewProduct from './newProduct';
import StoreLanding from './store';
import RaftLanding from './store/products/RaftLanding.js';
import KayakLanding from './store/products/KayakLanding.js';
import SUPLanding from './store/products/SUPLanding.js';
import ProductShow from './store/products/productShow';
import ShoppingCart from './cart';
import NotFound from './NotFound';
import Orders from './orders';
// import AboutUs from './about';
import FlashMessage from './FlashMsg';
import Landing from './landing';
import CookieLegal from './CookieLegal';
import Legal from './legal';
import RedirectTo from './RedirectState';
import Success from './cart/checkout/Success';

//Always shown partials
import Navigation from './partials/Navbar';
import Footer from './partials/Footer';

//Import global styles
import GlobalStyle from '../styles/globalStyles';
//Import Global site theme
import theme from '../styles/theme';

const App = () => {
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <CookieLegal />
        <Navigation />
        <FlashMessage />
        <RedirectTo />
        <Switch>
          <Route path='/login' exact>
            <Login />
          </Route>
          <Route path='/signup' exact>
            <Signup />
          </Route>
          <Route path='/new-product' exact>
            <NewProduct />
          </Route>
          <Route path='/update-product/:id' exact>
            <NewProduct update={true} />
          </Route>
          <Route path='/store' children={StoreLanding} />
          <Route path='/rafts' exact>
            <RaftLanding />
          </Route>
          <Route path='/rafts/:id'>
            <ProductShow />
          </Route>
          <Route path='/kayaks' exact>
            <KayakLanding />
          </Route>
          <Route path='/kayaks/:id'>
            <ProductShow />
          </Route>
          <Route path='/SUPs' exact>
            <SUPLanding />
          </Route>
          <Route path='/SUPs/:id'>
            <ProductShow />
          </Route>
          <Route path='/cart'>
            <ShoppingCart />
          </Route>
          <Route path='/orders'>
            <Orders />
          </Route>
          <Route path='/legal'>
            <Legal />
          </Route>
          <Route path='/success'>
            <Success />
          </Route>
          {/* <Route path='/about-us' children={AboutUs} /> */}
          <Route path='/' exact>
            <Landing />
          </Route>
          <Route path='*' exact>
            <NotFound />
          </Route>
        </Switch>
        <Footer />
      </ThemeProvider>
    </Router>
  );
};

export default App;
