import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { device } from '../../devices';

export const Container = styled.div`
  margin-top: 8rem;

  min-height: 50vh;
  background-color: ${(props) => props.theme.colorLightGray3};

  padding: 3rem 2.5rem;

  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  @media ${device.tabletL} {
    padding: 2rem 1rem;
  }
`;

export const Content = styled.main`
  background-color: ${(props) => props.theme.colorLightGray1};

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 95%;

  @media ${device.tabletL} {
    width: 100%;
    padding: 2rem 0;
  }
`;

export const Head = styled.div`
  width: 87%;

  display: flex;
  justify-content: space-between;
  align-items: center;

  font-size: 2.4rem;
  font-style: italic;

  color: ${(props) => props.theme.colorPrimary};
`;

export const DetailsTag = styled.span`
  flex: 0 0 20%;

  display: flex;

  justify-content: flex-end;
`;

export const TotalTag = styled.span`
  flex: 0 0 16%;
`;

export const Card = styled.div`
  width: 95%;

  display: flex;
  justify-content: space-between;
  align-items: center;

  margin: 1rem 0 3rem 0;

  box-shadow: ${(props) => props.theme.shadowDark};

  @media ${device.tablet} {
    flex-direction: column;
    border-radius: 3px;

    width: 65%;
  }
  @media ${device.tabletS} {
    width: 85%;
  }
  @media ${device.mobileM} {
    width: 95%;
  }
`;

export const Details = styled.div`
  flex: 1;
  display: flex;

  align-items: center;

  @media ${device.tablet} {
    flex-direction: column;
    justify-content: center;
  }
`;

export const CoverImg = styled.img`
  flex: 0 0 17%;
  height: 13rem;

  margin: 2rem;

  border-radius: 1rem;

  box-shadow: ${(props) => props.theme.shadowLight};

  @media ${device.tablet} {
    flex: 0;
    width: 60%;
    object-fit: contain;
  }
`;

export const Info = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;

  align-items: flex-start;

  text-align: center;

  @media ${device.tablet} {
    justify-content: center;
    align-items: center;

    margin-bottom: 2rem;
    font-size: 2rem;
  }
`;

export const TitleLink = styled(Link)`
  border-bottom: 1px solid ${(props) => props.theme.colorPrimary};

  min-width: 37%;
  max-width: 85%;

  text-align: start;

  margin: 1rem 0;

  text-shadow: ${(props) => props.theme.shadowVeryLight};

  transition: all 0.2s;
  &:link,
  &:visited {
    text-decoration: none;
    color: ${(props) => props.theme.colorPrimary};

    font-size: 2.5rem;

    @media ${device.tabletL} {
      font-size: 2.8rem;
    }
  }

  &:hover,
  &:focus {
    transform: translateY(-2px);
    color: ${(props) => props.theme.colorPrimaryDark};
  }

  &:active {
    transform: translateY(2px);
  }
`;

export const Quantity = styled.span`
  display: flex;
  align-items: center;

  text-align: start;

  margin-top: 1rem;
`;

export const QuantityInput = styled.div`
  font-size: 1.6rem;
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.3s;

  & > button {
    font-size: 2.4rem;
    line-height: 1.8rem;
    text-align: center;

    margin: 1rem;

    color: ${(props) => props.theme.colorPrimary};
    background-color: transparent;

    border: none;
    transition: all 0.3s;

    &:hover,
    &:focus {
      transform: scale(1.2);
    }
  }
`;

export const QuantitySpan = styled.span`
  border: 1px solid ${(props) => props.theme.colorLightGray4};
  border-radius: 3px;

  padding: 0 1rem;
  color: inherit;

  box-shadow: ${(props) => props.theme.shadowVeryLight};

  ${QuantityInput}:hover & {
    color: ${(props) => props.theme.colorPrimaryDark};

    border: 1px solid ${(props) => props.theme.colorPrimaryLight};
  }
`;

export const Color = styled.span`
  font-style: italic;
`;

export const Price = styled.span`
  font-style: italic;
`;

export const Subtotal = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const TotalPrice = styled.span`
  box-shadow: ${(props) => props.theme.shadowVeryLight};
  border-radius: 3px;

  font-size: 2.6rem;
  font-weight: 400;
  font-style: italic;

  color: ${(props) => props.theme.colorPrimary};

  padding: 1rem;

  @media ${device.tablet} {
    margin-bottom: 2rem;
  }
`;

export const Options = styled.div`
  flex: 0 0 10%;

  text-align: center;

  @media ${device.tablet} {
    flex: 0;

    width: 100%;

    display: flex;

    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export const ClearItem = styled.div`
  width: 4rem;

  margin: 0 auto;

  display: flex;

  justify-content: center;
  align-items: center;

  position: relative;

  color: ${(props) => props.theme.colorError};
  fill: ${(props) => props.theme.colorError};
  cursor: pointer;

  transition: all 0.3s;

  &:hover,
  &:focus {
    transform: scale(1.1);
    outline: none;
  }

  &:active {
    transform: translateY(3px);
  }

  & > svg {
    font-size: 3rem;

    border-bottom: 2px dotted ${(props) => props.theme.colorError};
  }

  @media ${device.tablet} {
    margin: 0;
    margin-top: 1rem;
  }
`;

export const Tooltip = styled.span`
  width: 18rem;
  font-size: 1.6rem;

  position: absolute;

  visibility: hidden;
  opacity: 0;

  transform: translateX(4rem);

  color: ${(props) => props.theme.colorLightGray1};
  border-radius: 5px;

  background-color: ${(props) => props.theme.colorDarkGray2};

  transition: all 0.3s;
  ${ClearItem}:hover &,
  ${ClearItem}:focus & {
    visibility: visible;
    opacity: 1;
    z-index: 2;

    transform: translateY(-3.5rem) translateX(4rem);
  }
`;

export const ClearCart = styled.button`
  background-color: transparent;
  border: none;
  font-family: inherit;

  border-bottom: 1px solid ${(props) => props.theme.colorError};

  align-self: flex-end;
  margin-right: 4rem;

  font-size: 1.8rem;
  line-height: 1.8rem;
  color: ${(props) => props.theme.colorError};

  display: flex;
  align-items: center;

  transition: all 0.2s;

  & > svg {
    font-size: 2.6rem;
    margin-right: 0.3rem;
  }

  &:hover,
  &:focus {
    transform: translateY(-2px);
    filter: brightness(150%);

    outline: none;
  }

  &:active {
    transform: translateY(2px);
    filter: brightness(80%);
  }
`;

export const PreCheckout = styled.div`
  min-width: 30%;

  align-self: flex-end;
  margin-right: 6rem;
  margin-top: 2rem;
  margin-bottom: 3rem;

  box-shadow: ${(props) => props.theme.shadowLight};
  border-radius: 4px;

  display: flex;
  flex-direction: column;

  color: ${(props) => props.theme.colorPrimaryDark};

  & > a {
    color: ${(props) => props.theme.colorLightGray1};
    margin: 0.8rem;
  }

  @media ${device.tabletL} {
    min-width: 40%;
    margin-right: 0;
    align-self: center;
    margin-top: 3rem;
  }
`;

export const CheckoutInfo = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;
`;

export const CheckoutGroup = styled.div`
  width: 100%;
  height: 5rem;

  border-bottom: 1px solid ${(props) => props.theme.colorLightGray4};

  display: flex;
  justify-content: space-around;
  align-items: center;

  font-size: 1.8rem;
`;

export const CheckoutLabel = styled.span``;

export const CheckoutDetail = styled.span`
  font-style: italic;
`;

export const CheckoutTotal = styled.span`
  width: 100%;
  height: 6rem;

  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;

  font-size: 1.8rem;

  border-top: 1px solid ${(props) => props.theme.colorPrimaryLight};
  border-bottom: 1px solid ${(props) => props.theme.colorPrimaryLight};
`;

export const CheckoutTotalPrice = styled.div`
  font-weight: 700;

  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: column;
`;

export const TotalSpan = styled.span`
  font-style: italic;
  font-size: 1.6rem;
  font-weight: 200;
`;

//Media
export const DetailsSmall = styled.span`
  font-size: 2.3rem;
  font-style: italic;

  align-self: flex-start;

  width: 50%;
  text-align: center;

  color: ${(props) => props.theme.colorPrimaryLight};
`;

export const TotalTagSmall = styled.span`
  font-size: 2.3rem;
  font-style: italic;

  align-self: flex-start;

  width: 50%;
  text-align: center;

  color: ${(props) => props.theme.colorPrimaryLight};

  margin-bottom: 1rem;
`;

export const RemoveTag = styled.span`
  font-size: 1.8rem;
  font-style: italic;

  text-align: center;

  color: ${(props) => props.theme.colorError};

  margin-bottom: 3rem;
`;
