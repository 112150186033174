import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import { device } from '../../../devices';

export const Container = styled.div`
  width: 95%;
  margin: 5rem auto;
  background-color: ${props => props.theme.colorLightGray1};
  box-shadow: ${props => props.theme.shadowLight};

  padding: 2rem;
`;

export const Tab = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;

  padding: 0;

  margin-bottom: 4rem;

  position: relative;

  &:after {
    position: absolute;
    content: '';
    width: 100%;
    bottom: 0;
    left: 0;
    border-bottom: 1px solid ${props => props.theme.colorPrimaryLight};

    z-index: 1;

    transition: all 0.2s;
  }
`;

export const TabItem = styled.button`
  flex: 0 0 10%;
  height: 5rem;

  border: ${props => (props.selected ? '2px' : '1px')} solid
    ${props => props.theme.colorPrimaryLight};
  border-bottom: ${props => (props.selected ? '2px' : '1px')} solid
    ${props => props.theme.colorLightGray1};
  border-radius: 4px;

  color: ${props => props.theme.colorPrimaryDark};

  z-index: ${props => (props.selected ? '2' : '0')};

  font-size: ${props => (props.selected ? '2rem' : '1.8rem')};

  transform: ${props => (props.selected ? 'scale(1.1)' : '')};
  background-color: ${props => props.theme.colorLightGray1};

  transition: all 0.2s;

  &:focus,
  &:hover {
    outline: none;
    transform: ${props => (props.selected ? '' : 'scale(1.05)')};

    color: ${props => (props.selected ? '' : props.theme.colorPrimaryLight)};
  }
  @media ${device.tabletL} {
    padding: 0 1rem;
  }
`;

export const Desc = styled(ReactMarkdown)`
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  & > h1,
  & > h2,
  & > h3 {
    color: ${props => props.theme.colorPrimaryDark};
    text-shadow: ${props => props.theme.shadowVeryLight};
    margin-bottom: 2rem;
  }

  & > hr {
    height: 1px;
    width: 50%;
    margin: 2rem 0;

    background-color: ${props => props.theme.colorPrimaryLight};
    border: none;
  }

  & > p {
    width: 60%;
    text-align: center;
    margin-bottom: 2rem;
  }

  & > ul li {
    font-style: italic;
    font-size: 1.8rem;
  }

  & > ul li::marker {
    color: ${props => props.theme.colorPrimaryLight};
  }
`;

export const Specs = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;
`;

export const Table = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Column = styled.div`
  width: 40%;
  display: flex;

  font-size: 1.9rem;

  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  border: 1px solid ${props => props.theme.colorPrimaryDark};
  border-radius: 4px;

  margin: 0 5rem;

  color: ${props => props.theme.colorPrimaryDark};

  box-shadow: ${props => props.theme.shadowVeryLight};

  @media ${device.tabletL} {
    width: 60%;
  }
  @media ${device.tablet} {
    width: 70%;
  }
  @media ${device.tabletS} {
    width: 80%;
    margin: 0;
  }
  @media ${device.mobileL} {
    width: 85%;
    margin: 0;
  }
  @media ${device.mobileM} {
    width: 90%;
  }
  @media ${device.mobileS} {
    width: 96%;
  }
`;

export const TableHead = styled.label`
  flex: 0 0 50%;
  height: 5rem;
  line-height: 5rem;

  font-style: italic;

  font-weight: 700;

  letter-spacing: 1px;

  border-right: 1px solid ${props => props.theme.colorPrimaryDark};

  text-align: center;

  &:not(:last-of-type) {
    border-bottom: 1px solid ${props => props.theme.colorPrimaryDark};
  }
`;

export const TableData = styled.span`
  flex: 0 0 50%;
  text-align: center;

  font-style: italic;

  height: 5rem;
  line-height: 5rem;

  &:not(:last-of-type) {
    border-bottom: 1px solid ${props => props.theme.colorPrimaryDark};
  }
`;

export const TableItem = styled.span``;
