import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 8rem;
  padding: 3rem;

  background-color: ${props => props.theme.colorLightGray3};
`;

export const Main = styled.div`
  width: 90%;
  margin: 0 auto;

  padding: 3rem;

  background-color: ${props => props.theme.colorLightGray1};
`;

export const Text = styled.p`
  width: 75%;
  margin: 0 auto;
`;
