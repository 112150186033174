import { createGlobalStyle } from 'styled-components';

import { device } from './devices';

const GlobalStyle = createGlobalStyle`
*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}
html {
  font-size: 62.5%;
  scroll-behavior: smooth;

  @media ${device.tablet} {
    font-size: 56.25%;
  }

}
body {
  //Reset
  box-sizing: border-box;
  padding: 2rem;
  //Typography
  font-family: "Lato", sans-serif;
  font-weight: ${props => props.theme.defaultFontWeight};
  font-size: ${props => props.theme.defaultFontSize};
  line-height: 1.7;
  color: ${props => props.theme.colorDarkGray3};
  //Background
  background-color: ${props => props.theme.colorPrimaryLight};
  min-height: 100vh;
  background-color: ${props => props.theme.colorPrimary};
  background-image: linear-gradient(to bottom right, ${props =>
    props.theme.colorPrimaryLight}, ${props => props.theme.colorPrimaryDark});
  background-repeat: no-repeat;

  position: relative;

  @media ${device.tablet} {
    padding: 0;
  }
}
::selection {
  background-color: ${props => props.theme.colorPrimary};
  color: ${props => props.theme.colorLightGray1};
}
`;

export default GlobalStyle;
