import { CLEAR_UPDATE, SET_UPDATE_PRODUCT } from '../actions/types';

const updateReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_UPDATE_PRODUCT:
      return { ...action.payload };
    case CLEAR_UPDATE:
      return {};
    default:
      return state;
  }
};

export default updateReducer;
