import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { displayMessage } from '../actions';
import { AiOutlineClose } from 'react-icons/ai';

import { Flash, FlashClose } from '../styles/components/Reusable';

const FlashMsg = ({ message, displayMessage }) => {
  useEffect(() => {
    if (message.message && message.time > 0) {
      setTimeout(() => {
        displayMessage('', 0);
      }, message.time * 1000);
    }
  });

  const closeMsg = () => {
    displayMessage('', 0);
  };

  if (message.message && message.time > 0) {
    return (
      <Flash
        error={message.error ? 'true' : ''}
        hide={message.message ? '' : 'true'}
      >
        <p>
          {message.error ? 'Error: ' : ''}
          {message.message}
        </p>
        <FlashClose onClick={() => closeMsg()}>
          <AiOutlineClose />
        </FlashClose>
      </Flash>
    );
  } else {
    return '';
  }
};

const mapStateToProps = ({ message }) => ({ message });

export default connect(
  mapStateToProps,
  { displayMessage }
)(FlashMsg);
