import React, { useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import * as Legal from '../../styles/components/Legal';
import { HeaderSecondary } from '../../styles/components/Reusable';

export default function Index() {
  const location = useLocation();
  const locationHash = location.hash;
  const privacyRef = useRef();
  const termsRef = useRef();
  const cookiesRef = useRef();

  useEffect(() => {
    if (locationHash === '#privacy') {
      privacyRef.current.scrollIntoView({
        behavior: 'smooth'
      });
    } else if (locationHash === '#terms') {
      termsRef.current.scrollIntoView({
        behavior: 'smooth'
      });
    } else if (locationHash === '#cookies') {
      cookiesRef.current.scrollIntoView({
        behavior: 'smooth'
      });
    }
  }, [locationHash]);

  return (
    <Legal.Container>
      <Legal.Main>
        <HeaderSecondary id='privacy' ref={privacyRef}>
          Privacy Policy
        </HeaderSecondary>
        <Legal.Text>
          <p>
            Your privacy is important to us. It is Teton Wilderness Supply&#39;s
            policy to respect your privacy and comply with any applicable law
            and regulation regarding any personal information we may collect
            about you, including across our website,{' '}
            <a href='https://tetonwildernesssupply.com'>
              https://tetonwildernesssupply.com
            </a>
            , and other sites we own and operate.{' '}
          </p>
          <p>
            Personal information is any information about you which can be used
            to identify you. This includes information about you as a person
            (such as name, address, and date of birth), your devices, payment
            details, and even information about how you use a website or online
            service.{' '}
          </p>
          <p>
            In the event our site contains links to third-party sites and
            services, please be aware that those sites and services have their
            own privacy policies. After following a link to any third-party
            content, you should read their posted privacy policy information
            about how they collect and use personal information. This Privacy
            Policy does not apply to any of your activities after you leave our
            site.{' '}
          </p>
          <p>This policy is effective as of 26 August 2021. </p>
          <p>Last updated: 26 August 2021 </p>
          <h3>Information We Collect</h3>
          <p>
            Information we collect includes both information you knowingly and
            actively provide us when using or participating in any of our
            services and promotions, and any information automatically sent by
            your devices in the course of accessing our products and services.{' '}
          </p>
          <h4>Log Data</h4>
          <p>
            When you visit our website, our servers may automatically log the
            standard data provided by your web browser. It may include your
            device’s Internet Protocol (IP) address, your browser type and
            version, the pages you visit, the time and date of your visit, the
            time spent on each page, and other details about your visit.{' '}
          </p>
          <p>
            Additionally, if you encounter certain errors while using the site,
            we may automatically collect data about the error and the
            circumstances surrounding its occurrence. This data may include
            technical details about your device, what you were trying to do when
            the error happened, and other technical information relating to the
            problem. You may or may not receive notice of such errors, even in
            the moment they occur, that they have occurred, or what the nature
            of the error is.{' '}
          </p>
          <p>
            Please be aware that while this information may not be personally
            identifying by itself, it may be possible to combine it with other
            data to personally identify individual persons.{' '}
          </p>
          <h4>Device Data</h4>
          <p>
            When you visit our website or interact with our services, we may
            automatically collect data about your device, such as:{' '}
          </p>
          <ul>
            <li>Device Type</li>
            <li>Unique device identifiers</li>
            <li>Geo-location data</li>
          </ul>
          <p>
            Data we collect can depend on the individual settings of your device
            and software. We recommend checking the policies of your device
            manufacturer or software provider to learn what information they
            make available to us.{' '}
          </p>
          <h4>Personal Information</h4>
          <p>
            We may ask for personal information which may include one or more of
            the following:{' '}
          </p>
          <ul>
            <li>Name</li>
            <li>Email</li>
            <li>Date of birth</li>
            <li>Phone/mobile number</li>
            <li>Home/mailing address</li>
          </ul>
          <h4>User-Generated Content</h4>
          <p>
            We consider &ldquo;user-generated content&rdquo; to be materials
            voluntarily supplied to us by our users for the purpose of
            publication on our website and/or social media channels. All
            user-generated content is associated with the account or email
            address used to submit the materials.{' '}
          </p>
          <p>
            Please be aware that any content you submit for the purpose of
            publication will be public after posting (and subsequent review or
            vetting process). Once published, it may be accessible to third
            parties not covered under this privacy policy.{' '}
          </p>
          <h4>Legitimate Reasons for Processing Your Personal Information</h4>
          <p>
            We only collect and use your personal information when we have a
            legitimate reason for doing so. In which instance, we only collect
            personal information that is reasonably necessary to provide our
            services to you.{' '}
          </p>
          <h4>Collection and Use of Information</h4>
          <p>
            We may collect personal information from you when you do any of the
            following on our website:{' '}
          </p>
          <ul>
            <li>
              Post a comment or review or otherwise participate in our online
              community
            </li>
            <li>Use a mobile device or web browser to access our content</li>
            <li>
              Contact us via email, social media, or on any similar technologies
            </li>
            <li>When you mention us on social media</li>
          </ul>
          <p>
            We may collect, hold, use, and disclose information for the
            following purposes, and personal information will not be further
            processed in a manner that is incompatible with these purposes:{' '}
          </p>
          <ul>
            <li>
              to provide you with our platform's core features and services
            </li>
            <li>
              to enable you to customize or personalize your experience of our
              website
            </li>
            <li>to contact and communicate with you</li>
            <li>
              for analytics, market research, and business development,
              including to operate and improve our website, associated
              applications, and associated social media platforms
            </li>
            <li>for internal record keeping and administrative purposes</li>
            <li>
              to comply with our legal obligations and resolve any disputes that
              we may have
            </li>
            <li>
              to attribute any content (e.g. posts and comments) you submit that
              we publish on our website
            </li>
            <li>
              for security and fraud prevention, and to ensure that our sites
              and apps are safe, secure, and used in line with our terms of use
            </li>
          </ul>
          <p>
            Please be aware that we may combine information we collect about you
            with general information or research data we receive from other
            trusted sources.{' '}
          </p>
          <h4>Security of Your Personal Information</h4>
          <p>
            When we collect and process personal information, and while we
            retain this information, we will protect it within commercially
            acceptable means to prevent loss and theft, as well as unauthorized
            access, disclosure, copying, use, or modification.{' '}
          </p>
          <p>
            Although we will do our best to protect the personal information you
            provide to us, we advise that no method of electronic transmission
            or storage is 100% secure, and no one can guarantee absolute data
            security. We will comply with laws applicable to us in respect of
            any data breach.{' '}
          </p>
          <p>
            You are responsible for selecting any password and its overall
            security strength, ensuring the security of your own information
            within the bounds of our services.{' '}
          </p>
          <h4>How Long We Keep Your Personal Information</h4>
          <p>
            We keep your personal information only for as long as we need to.
            This time period may depend on what we are using your information
            for, in accordance with this privacy policy. If your personal
            information is no longer required, we will delete it or make it
            anonymous by removing all details that identify you.{' '}
          </p>
          <p>
            However, if necessary, we may retain your personal information for
            our compliance with a legal, accounting, or reporting obligation or
            for archiving purposes in the public interest, scientific, or
            historical research purposes or statistical purposes.{' '}
          </p>
          <h3>Children’s Privacy</h3>
          <p>
            We do not aim any of our products or services directly at children
            under the age of 13, and we do not knowingly collect personal
            information about children under 13.{' '}
          </p>
          <h3>Disclosure of Personal Information to Third Parties</h3>
          <p>We may disclose personal information to: </p>
          <ul>
            <li>a parent, subsidiary, or affiliate of our company</li>
            <li>
              third party service providers for the purpose of enabling them to
              provide their services, for example, IT service providers, data
              storage, hosting and server providers, advertisers, or analytics
              platforms
            </li>
            <li>our employees, contractors, and/or related entities</li>
            <li>our existing or potential agents or business partners</li>
            <li>
              courts, tribunals, regulatory authorities, and law enforcement
              officers, as required by law, in connection with any actual or
              prospective legal proceedings, or in order to establish, exercise,
              or defend our legal rights
            </li>
            <li>
              third parties, including agents or sub-contractors, who assist us
              in providing information, products, services, or direct marketing
              to you
            </li>
            <li>third parties to collect and process data</li>
          </ul>
          <h3>International Transfers of Personal Information</h3>
          <p>
            The personal information we collect is stored and/or processed in
            United States, or where we or our partners, affiliates, and
            third-party providers maintain facilities.{' '}
          </p>
          <p>
            The countries to which we store, process, or transfer your personal
            information may not have the same data protection laws as the
            country in which you initially provided the information. If we
            transfer your personal information to third parties in other
            countries: (i) we will perform those transfers in accordance with
            the requirements of applicable law; and (ii) we will protect the
            transferred personal information in accordance with this privacy
            policy.{' '}
          </p>
          <h3>Your Rights and Controlling Your Personal Information</h3>
          <p>
            You always retain the right to withhold personal information from
            us, with the understanding that your experience of our website may
            be affected. We will not discriminate against you for exercising any
            of your rights over your personal information. If you do provide us
            with personal information you understand that we will collect, hold,
            use and disclose it in accordance with this privacy policy. You
            retain the right to request details of any personal information we
            hold about you.{' '}
          </p>
          <p>
            If we receive personal information about you from a third party, we
            will protect it as set out in this privacy policy. If you are a
            third party providing personal information about somebody else, you
            represent and warrant that you have such person’s consent to provide
            the personal information to us.{' '}
          </p>
          <p>
            If you have previously agreed to us using your personal information
            for direct marketing purposes, you may change your mind at any time.
            We will provide you with the ability to unsubscribe from our
            email-database or opt out of communications. Please be aware we may
            need to request specific information from you to help us confirm
            your identity.{' '}
          </p>
          <p>
            If you believe that any information we hold about you is inaccurate,
            out of date, incomplete, irrelevant, or misleading, please contact
            us using the details provided in this privacy policy. We will take
            reasonable steps to correct any information found to be inaccurate,
            incomplete, misleading, or out of date.{' '}
          </p>
          <p>
            If you believe that we have breached a relevant data protection law
            and wish to make a complaint, please contact us using the details
            below and provide us with full details of the alleged breach. We
            will promptly investigate your complaint and respond to you, in
            writing, setting out the outcome of our investigation and the steps
            we will take to deal with your complaint. You also have the right to
            contact a regulatory body or data protection authority in relation
            to your complaint.{' '}
          </p>
          <h3>Use of Cookies</h3>
          <p>
            We use &ldquo;cookies&rdquo; to collect information about you and
            your activity across our site. A cookie is a small piece of data
            that our website stores on your computer, and accesses each time you
            visit, so we can understand how you use our site. This helps us
            serve you content based on preferences you have specified.{' '}
          </p>
          <p>Please refer to our Cookie Policy for more information. </p>
          <h3>Limits of Our Policy</h3>
          <p>
            Our website may link to external sites that are not operated by us.
            Please be aware that we have no control over the content and
            policies of those sites, and cannot accept responsibility or
            liability for their respective privacy practices.{' '}
          </p>
          <h3>Changes to This Policy</h3>
          <p>
            At our discretion, we may change our privacy policy to reflect
            updates to our business processes, current acceptable practices, or
            legislative or regulatory changes. If we decide to change this
            privacy policy, we will post the changes here at the same link by
            which you are accessing this privacy policy.{' '}
          </p>
          <p>
            If required by law, we will get your permission or give you the
            opportunity to opt in to or opt out of, as applicable, any new uses
            of your personal information.{' '}
          </p>
          <h3>Contact Us</h3>
          <p>
            For any questions or concerns regarding your privacy, you may
            contact us using the following details:{' '}
          </p>
          <p>
            Nathan Nelson
            <br />
            nelsnath@gmail.com{' '}
          </p>
        </Legal.Text>
        <HeaderSecondary id='terms' ref={termsRef}>
          Terms of Service
        </HeaderSecondary>
        <Legal.Text>
          <p>
            These Terms of Service govern your use of the website located at{' '}
            <a href='https://tetonwildernesssupply.com'>
              https://tetonwildernesssupply.com
            </a>{' '}
            and any related services provided by Teton Wilderness Supply.{' '}
          </p>
          <p>
            By accessing{' '}
            <a href='https://tetonwildernesssupply.com'>
              https://tetonwildernesssupply.com
            </a>
            , you agree to abide by these Terms of Service and to comply with
            all applicable laws and regulations. If you do not agree with these
            Terms of Service, you are prohibited from using or accessing this
            website or using any other services provided by Teton Wilderness
            Supply.{' '}
          </p>
          <p>
            We, Teton Wilderness Supply, reserve the right to review and amend
            any of these Terms of Service at our sole discretion. Upon doing so,
            we will update this page. Any changes to these Terms of Service will
            take effect immediately from the date of publication.{' '}
          </p>
          <p>These Terms of Service were last updated on 26 August 2021. </p>
          <h3>Limitations of Use</h3>
          <p>
            By using this website, you warrant on behalf of yourself, your
            users, and other parties you represent that you will not:{' '}
          </p>
          <ol>
            <li>
              modify, copy, prepare derivative works of, decompile, or reverse
              engineer any materials and software contained on this website;
            </li>
            <li>
              remove any copyright or other proprietary notations from any
              materials and software on this website;
            </li>
            <li>
              transfer the materials to another person or “mirror” the materials
              on any other server;
            </li>
            <li>
              knowingly or negligently use this website or any of its associated
              services in a way that abuses or disrupts our networks or any
              other service Teton Wilderness Supply provides;
            </li>
            <li>
              use this website or its associated services to transmit or publish
              any harassing, indecent, obscene, fraudulent, or unlawful
              material;
            </li>
            <li>
              use this website or its associated services in violation of any
              applicable laws or regulations;
            </li>
            <li>
              use this website in conjunction with sending unauthorized
              advertising or spam;
            </li>
            <li>
              harvest, collect, or gather user data without the user’s consent;
              or
            </li>
            <li>
              use this website or its associated services in such a way that may
              infringe the privacy, intellectual property rights, or other
              rights of third parties.
            </li>
          </ol>
          <h3>Intellectual Property</h3>
          <p>
            The intellectual property in the materials contained in this website
            are owned by or licensed to Teton Wilderness Supply and are
            protected by applicable copyright and trademark law. We grant our
            users permission to download one copy of the materials for personal,
            non-commercial transitory use.{' '}
          </p>
          <p>
            This constitutes the grant of a license, not a transfer of title.
            This license shall automatically terminate if you violate any of
            these restrictions or the Terms of Service, and may be terminated by
            Teton Wilderness Supply at any time.{' '}
          </p>
          <h3>User-Generated Content</h3>
          <p>
            You retain your intellectual property ownership rights over content
            you submit to us for publication on our website. We will never claim
            ownership of your content, but we do require a license from you in
            order to use it.{' '}
          </p>
          <p>
            When you use our website or its associated services to post, upload,
            share, or otherwise transmit content covered by intellectual
            property rights, you grant to us a non-exclusive, royalty-free,
            transferable, sub-licensable, worldwide license to use, distribute,
            modify, run, copy, publicly display, translate, or otherwise create
            derivative works of your content in a manner that is consistent with
            your privacy preferences and our Privacy Policy.{' '}
          </p>
          <p>
            The license you grant us can be terminated at any time by deleting
            your content. However, to the extent that we (or our partners) have
            used your content in connection with commercial or sponsored
            content, the license will continue until the relevant commercial or
            post has been discontinued by us.{' '}
          </p>
          <h3>Liability</h3>
          <p>
            Our website and the materials on our website are provided on an 'as
            is' basis. To the extent permitted by law, Teton Wilderness Supply
            makes no warranties, expressed or implied, and hereby disclaims and
            negates all other warranties including, without limitation, implied
            warranties or conditions of merchantability, fitness for a
            particular purpose, or non-infringement of intellectual property, or
            other violation of rights.{' '}
          </p>
          <p>
            In no event shall Teton Wilderness Supply or its suppliers be liable
            for any consequential loss suffered or incurred by you or any third
            party arising from the use or inability to use this website or the
            materials on this website, even if Teton Wilderness Supply or an
            authorized representative has been notified, orally or in writing,
            of the possibility of such damage.{' '}
          </p>
          <p>
            In the context of this agreement, &ldquo;consequential loss&rdquo;
            includes any consequential loss, indirect loss, real or anticipated
            loss of profit, loss of benefit, loss of revenue, loss of business,
            loss of goodwill, loss of opportunity, loss of savings, loss of
            reputation, loss of use and/or loss or corruption of data, whether
            under statute, contract, equity, tort (including negligence),
            indemnity, or otherwise.{' '}
          </p>
          <p>
            Because some jurisdictions do not allow limitations on implied
            warranties, or limitations of liability for consequential or
            incidental damages, these limitations may not apply to you.{' '}
          </p>
          <h3>Accuracy of Materials</h3>
          <p>
            The materials appearing on our website are not comprehensive and are
            for general information purposes only. Teton Wilderness Supply does
            not warrant or make any representations concerning the accuracy,
            likely results, or reliability of the use of the materials on this
            website, or otherwise relating to such materials or on any resources
            linked to this website.{' '}
          </p>
          <h3>Links</h3>
          <p>
            Teton Wilderness Supply has not reviewed all of the sites linked to
            its website and is not responsible for the contents of any such
            linked site. The inclusion of any link does not imply endorsement,
            approval, or control by Teton Wilderness Supply of the site. Use of
            any such linked site is at your own risk and we strongly advise you
            make your own investigations with respect to the suitability of
            those sites.{' '}
          </p>
          <h3>Right to Terminate</h3>
          <p>
            We may suspend or terminate your right to use our website and
            terminate these Terms of Service immediately upon written notice to
            you for any breach of these Terms of Service.{' '}
          </p>
          <h3>Severance</h3>
          <p>
            Any term of these Terms of Service which is wholly or partially void
            or unenforceable is severed to the extent that it is void or
            unenforceable. The validity of the remainder of these Terms of
            Service is not affected.{' '}
          </p>
          <h3>Governing Law</h3>
          <p>
            These Terms of Service are governed by and construed in accordance
            with the laws of Idaho. You irrevocably submit to the exclusive
            jurisdiction of the courts in that State or location.{' '}
          </p>
        </Legal.Text>
        <HeaderSecondary id='cookies' ref={cookiesRef}>
          Cookie Policy
        </HeaderSecondary>
        <Legal.Text>
          <p>
            We use cookies to help improve your experience of our website at{' '}
            <a href='https://tetonwildernesssupply.com'>
              https://tetonwildernesssupply.com
            </a>
            . This cookie policy is part of Teton Wilderness Supply&#39;s
            privacy policy. It covers the use of cookies between your device and
            our site.{' '}
          </p>
          <p>
            We also provide basic information on third-party services we may
            use, who may also use cookies as part of their service. This policy
            does not cover their cookies.{' '}
          </p>
          <p>
            If you don’t wish to accept cookies from us, you should instruct
            your browser to refuse cookies from{' '}
            <a href='https://tetonwildernesssupply.com'>
              https://tetonwildernesssupply.com
            </a>
            . In such a case, we may be unable to provide you with some of your
            desired content and services.{' '}
          </p>
          <h3>What is a cookie?</h3>
          <p>
            A cookie is a small piece of data that a website stores on your
            device when you visit. It typically contains information about the
            website itself, a unique identifier that allows the site to
            recognize your web browser when you return, additional data that
            serves the cookie’s purpose, and the lifespan of the cookie itself.{' '}
          </p>
          <p>
            Cookies are used to enable certain features (e.g. logging in), track
            site usage (e.g. analytics), store your user settings (e.g. time
            zone, notification preferences), and to personalize your content
            (e.g. advertising, language).{' '}
          </p>
          <p>
            Cookies set by the website you are visiting are usually referred to
            as first-party cookies. They typically only track your activity on
            that particular site.{' '}
          </p>
          <p>
            Cookies set by other sites and companies (i.e. third parties) are
            called third-party cookies They can be used to track you on other
            websites that use the same third-party service.{' '}
          </p>
          <h3>Types of cookies and how we use them</h3>
          <h4>Essential cookies</h4>
          <p>
            Essential cookies are crucial to your experience of a website,
            enabling core features like user logins, account management,
            shopping carts, and payment processing.{' '}
          </p>
          <p>
            We use essential cookies to enable certain functions on our website.{' '}
          </p>
          <h4>Performance cookies</h4>
          <p>
            Performance cookies track how you use a website during your visit.
            Typically, this information is anonymous and aggregated, with
            information tracked across all site users. They help companies
            understand visitor usage patterns, identify and diagnose problems or
            errors their users may encounter, and make better strategic
            decisions in improving their audience’s overall website experience.
            These cookies may be set by the website you’re visiting
            (first-party) or by third-party services. They do not collect
            personal information about you.{' '}
          </p>
          <p>We use performance cookies on our site. </p>
          <h4>Functionality cookies</h4>
          <p>
            Functionality cookies are used to collect information about your
            device and any settings you may configure on the website you’re
            visiting (like language and time zone settings). With this
            information, websites can provide you with customized, enhanced, or
            optimized content and services. These cookies may be set by the
            website you’re visiting (first-party) or by third-party services.{' '}
          </p>
          <p>
            We use functionality cookies for selected features on our site.{' '}
          </p>
          <h4>Targeting/advertising cookies</h4>
          <p>
            Targeting/advertising cookies help determine what promotional
            content is most relevant and appropriate to you and your interests.
            Websites may use them to deliver targeted advertising or limit the
            number of times you see an advertisement. This helps companies
            improve the effectiveness of their campaigns and the quality of
            content presented to you. These cookies may be set by the website
            you’re visiting (first-party) or by third-party services.
            Targeting/advertising cookies set by third-parties may be used to
            track you on other websites that use the same third-party service.{' '}
          </p>
          <p>We do not use this type of cookie on our site. </p>
        </Legal.Text>
      </Legal.Main>
    </Legal.Container>
  );
}
