import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { device } from '../devices';

export const Container = styled.div`
  min-height: 50vh;
  background-color: ${props => props.theme.colorDarkGray3};
  color: ${props => props.theme.colorLightGray3};
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  position: relative;
  padding: 3rem;

  @media ${device.tablet} {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const Links = styled.div`
  display: flex;
  flex-direction: column;

  @media ${device.tablet} {
    margin: 4rem 0;
    align-items: center;
  }
`;

export const LinkItem = styled(Link)`
  height: 3rem;
  transition: all 0.3s;

  &:link,
  &:visited {
    text-decoration: none;
    color: inherit;
    font-size: 1.8rem;
  }

  &:hover {
    color: ${props => props.theme.colorPrimary};
  }

  &:active {
    transform: translateY(2px);
  }
`;

export const Contact = styled.div``;

export const ContactItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
`;

export const LinksHeader = styled.h3`
  text-align: center;
  font-weight: ${props => props.theme.defaultFontWeight};
  border-bottom: 1px solid ${props => props.theme.colorPrimary};
  margin-bottom: 0.7rem;
  font-size: 2rem;
  font-weight: ${props => props.theme.lightFontWeight};
  width: 20rem;
`;

export const ContactItem = styled(Link)`
  transition: all 0.2s;
  cursor: ${props => (props.type === 'phone' ? 'default' : '')};

  &:link,
  &:visited {
    text-decoration: none;
    color: inherit;

    & > svg {
      margin: 0 1rem;
      font-size: ${props => (props.type === 'facebook' ? '1.9rem' : '2.5rem')};
    }
  }

  &:hover {
    color: ${props =>
      props.type === 'facebook' ? props.theme.colorTertiaryLight : '#C71610'};
    transform: scale(1.1);
    transform: ${props => (props.type === 'phone' ? 'skew(5deg)' : '')};

    color: ${props => (props.type === 'phone' ? props.theme.colorPrimary : '')};
  }

  &:active {
    transform: translateY(2px);
  }
`;

export const Legal = styled.div`
  display: flex;
  flex-direction: column;

  @media ${device.tablet} {
    align-items: center;
    margin-top: 3rem;
  }
`;

export const LegalLink = styled(Link)`
  height: 3rem;
  transition: all 0.3s;

  &:link,
  &:visited {
    font-size: 1.8rem;
    text-decoration: none;
    color: inherit;
  }

  &:hover {
    color: ${props => props.theme.colorPrimary};
  }

  &:active {
    transform: translateY(2px);
  }
`;

export const Copywrite = styled.span`
  margin-top: 2rem;
`;
