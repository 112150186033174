import { connect } from 'react-redux';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { reduxForm, reset, Field } from 'redux-form';
import { BiUserCircle } from 'react-icons/bi';
import { BsTrash } from 'react-icons/bs';

import * as Reviews from '../../../../styles/components/Products/ProductShow/Reviews';
import {
  HeaderSecondary,
  Paginate,
  PageMove,
  PageLink,
  HeaderTertiary,
  ButtonSubmit
} from '../../../../styles/components/Reusable';
import {
  fetchReviews,
  clearReviews,
  displayMessage
} from '../../../../actions';
import {
  Error,
  Input,
  Textarea,
  Label,
  StarInput,
  StarLabel,
  Rating,
  ButtonWrapper
} from '../../../../styles/components/Form';
import { deleteReview } from '../../../../helpers/axios';

//Redux Form Validator
const validate = values => {
  const errors = {};

  if (!values.email) {
    errors.email = 'Please provide an email address.';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid email address.';
  }

  if (!values.name) {
    errors.name = 'Please provide a name.';
  }

  if (!values.body) {
    errors.body = 'Please enter some text.';
  } else if (values.body.length > 500) {
    errors.body = 'Review must be 500 characters or less.';
  }

  if (!values.rating) {
    errors.rating = 'Please provide a rating.';
  }
  return errors;
};

//Redux form Field renderers
const renderInput = ({ input, meta: { touched, error }, ...rest }) => (
  <>
    <Input
      onChange={(event, value) => input.onChange(value)}
      error={touched && error}
      {...input}
      {...rest}
    />
    {touched && error && <Error>{error}</Error>}
  </>
);

const renderTextarea = ({ input, meta: { touched, error }, ...rest }) => {
  return (
    <>
      <Textarea
        onChange={(event, value) => input.onChange(value)}
        error={touched && error}
        {...input}
        {...rest}
      />
      {touched && error && <Error>{error}</Error>}
    </>
  );
};

const renderStarInput = ({ input, meta: { touched, error }, ...rest }) => {
  return (
    <>
      <StarInput
        onChange={(event, value) => input.onChange(value)}
        error={touched && error}
        {...input}
        {...rest}
      />
      {touched && error && <Error>{error}</Error>}
    </>
  );
};

//Main component
function Index({
  product,
  reviews,
  fetchReviews,
  clearReviews,
  handleSubmit,
  displayMessage,
  user
}) {
  let [page, setPage] = useState(1);
  let [star, setStar] = useState(0);
  const reviewsLength = reviews.data ? reviews.data.reviews.length : 0;

  useEffect(() => {
    if (product._id) {
      fetchReviews(product._id, page);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product, reviewsLength]);

  const renderPaginate = () => {
    if (!reviews) {
      return '';
    }
    const renderLinks = () => {
      const pages = Math.ceil(reviews.results / 5);
      let links = [];
      for (let i = 1; i <= pages; i++) {
        links.push(
          <PageLink key={`page ${i}`} onClick={() => setPage(i)}>
            {i}
          </PageLink>
        );
      }
      return links;
    };
    return (
      <Paginate>
        <PageMove onClick={() => (page > 1 ? setPage((page -= 1)) : null)}>
          Prev
        </PageMove>
        {renderLinks()}
        <PageMove
          onClick={() =>
            page < reviews.totalResults / 9 ? setPage((page += 1)) : null
          }
        >
          Next
        </PageMove>
      </Paginate>
    );
  };

  const onDeleteClick = async id => {
    try {
      await deleteReview(id);

      displayMessage(`Review delete successfully`, 3);
      await fetchReviews(product._id);
    } catch (err) {
      if (err) {
        displayMessage(`Couldn't delete Review: ${err}`, 5, true);
      }
    }
  };

  const renderReviews = () => {
    if (!reviews.data) {
      return (
        <div>Be the first one to review by filling out the form below!</div>
      );
    }
    return reviews.data.reviews.map(review => {
      const date = new Date(review.createdAt);

      return (
        <Reviews.Review key={review.id}>
          <Reviews.Head>
            <Reviews.Person>
              <BiUserCircle />
              <Reviews.Name>{review.name}</Reviews.Name>
              <Reviews.Date> - {date.toDateString()}</Reviews.Date>
            </Reviews.Person>
            <Reviews.Stars>
              {review.rating >= 1 ? (
                <Reviews.StarFilled />
              ) : (
                <Reviews.StarEmpty />
              )}
              {review.rating >= 2 ? (
                <Reviews.StarFilled />
              ) : (
                <Reviews.StarEmpty />
              )}
              {review.rating >= 3 ? (
                <Reviews.StarFilled />
              ) : (
                <Reviews.StarEmpty />
              )}
              {review.rating >= 4 ? (
                <Reviews.StarFilled />
              ) : (
                <Reviews.StarEmpty />
              )}
              {review.rating >= 5 ? (
                <Reviews.StarFilled />
              ) : (
                <Reviews.StarEmpty />
              )}
            </Reviews.Stars>
          </Reviews.Head>
          <Reviews.Body>{review.body}</Reviews.Body>
          {user ? (
            <Reviews.Delete onClick={() => onDeleteClick(review.id)}>
              <BsTrash />
            </Reviews.Delete>
          ) : (
            ''
          )}
        </Reviews.Review>
      );
    });
  };

  return (
    <Reviews.Container id="ratings">
      <HeaderSecondary>Reviews</HeaderSecondary>
      <Reviews.Main>
        <Reviews.Results>
          Total reviews: {reviews ? reviews.results : 0}
        </Reviews.Results>
        {reviews ? renderReviews() : ''}
      </Reviews.Main>
      {renderPaginate()}
      <Reviews.Form name="newReview" onSubmit={handleSubmit}>
        <HeaderTertiary>Post a review</HeaderTertiary>
        <Label htmlFor="email">Enter your email</Label>
        <Field
          type="email"
          name="email"
          id="email"
          placeholder="example@email.com"
          component={renderInput}
        />
        <Label htmlFor="name">Enter your name</Label>
        <Field
          type="text"
          name="name"
          id="name"
          placeholder="ex: John Smith"
          component={renderInput}
        />
        <Rating>
          <Label htmlFor="rating">Your Rating</Label>
          <Field
            id="star1"
            onClick={() => setStar(1)}
            value="1"
            type="radio"
            component={renderStarInput}
            name="rating"
          />
          <StarLabel htmlFor="star1">
            {star >= 1 ? <Reviews.StarFilled /> : <Reviews.StarEmpty />}
          </StarLabel>

          <Field
            id="star2"
            value="2"
            onClick={() => setStar(2)}
            type="radio"
            component={renderStarInput}
            name="rating"
          />
          <StarLabel htmlFor="star2">
            {star >= 2 ? <Reviews.StarFilled /> : <Reviews.StarEmpty />}
          </StarLabel>

          <Field
            id="star3"
            value="3"
            onClick={() => setStar(3)}
            type="radio"
            component={renderStarInput}
            name="rating"
          />
          <StarLabel htmlFor="star3">
            {star >= 3 ? <Reviews.StarFilled /> : <Reviews.StarEmpty />}
          </StarLabel>

          <Field
            id="star4"
            value="4"
            onClick={() => setStar(4)}
            type="radio"
            component={renderStarInput}
            name="rating"
          />
          <StarLabel htmlFor="star4">
            {star >= 4 ? <Reviews.StarFilled /> : <Reviews.StarEmpty />}
          </StarLabel>

          <Field
            id="star5"
            onClick={() => setStar(5)}
            value="5"
            type="radio"
            component={renderStarInput}
            name="rating"
          />
          <StarLabel htmlFor="star5">
            {star >= 5 ? <Reviews.StarFilled /> : <Reviews.StarEmpty />}
          </StarLabel>
        </Rating>
        <Label htmlFor="body">Your review</Label>
        <Field
          rows="8"
          cols="50"
          name="body"
          id="body"
          placeholder="type your review here"
          component={renderTextarea}
        />
        <ButtonWrapper>
          <ButtonSubmit type="submit" value="Submit your review" />
        </ButtonWrapper>
      </Reviews.Form>
    </Reviews.Container>
  );
}

const onReviewSubmit = async (values, dispatch, props) => {
  try {
    const id = props.product._id;
    await axios.post(`/api/v1/products/${id}/reviews`, values, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json'
      },
      withCredentials: true
    });

    await fetchReviews(props.product._id, 1)(dispatch);
    await dispatch(reset('newReview'));
    await dispatch(displayMessage('Review created successfully!', 5));
  } catch (err) {
    dispatch(
      displayMessage(
        'Only one review allowed per product, per customer!',
        5,
        true
      )
    );
  }
};

const mapStateToProps = ({ user }) => {
  return { user };
};

const connnectedReviews = connect(
  mapStateToProps,
  { fetchReviews, clearReviews, displayMessage }
)(Index);

export default reduxForm({
  form: 'newReview',
  onSubmit: onReviewSubmit,
  validate
})(connnectedReviews);
