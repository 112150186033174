import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { redirectTo } from '../actions';
import { scrollToTop } from '../helpers';

export const RedirectState = props => {
  useEffect(() => {
    if (props.redirectState) {
      props.redirectTo(false);
    }
  });
  if (props.redirectState) {
    scrollToTop();
    return <Redirect to={props.redirectState} />;
  } else {
    return '';
  }
};

const mapStateToProps = state => ({ redirectState: state.redirect });

export default connect(
  mapStateToProps,
  { redirectTo }
)(RedirectState);
