import styled from 'styled-components';
import { device } from '../../devices';

export const Container = styled.div`
  min-height: 55vh;
  background-color: ${props => props.theme.colorLightGray3};
  padding: 5rem;

  @media ${device.mobileL} {
    padding: 2rem;
  }
  @media ${device.mobileS} {
    padding: 1rem;
  }
`;

export const Main = styled.div`
  margin: 5rem 0;
  background-color: ${props => props.theme.colorLightGray1};
`;

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 5rem;

  @media ${device.tabletL} {
    flex-direction: column;
  }
`;

export const Content = styled.div`
  flex: 0 0 20%;
  font-size: 1.7rem;

  @media ${device.tabletL} {
    margin: 2rem 4rem;
  }
`;

export const ImageCircle = styled.div`
  width: 25rem;
  height: 25rem;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  margin: auto 4rem;
  box-shadow: ${props => props.theme.shadowDark};

  @media ${device.tabletL} {
    margin: 1rem auto;
  }
`;

export const Img = styled.img`
  height: 100%;

  transform: translateX(-7rem) scale(1.4);
`;

export const Text = styled.p``;
