import React from 'react';
import { scrollToTop } from '../../helpers';
import {
  Button,
  Heading,
  HeaderPrimary,
  HeaderSecondary
} from '../../styles/components/Reusable';
import * as Store from '../../styles/components/Store';

export default function index() {
  return (
    <Store.Container>
      <Heading id="shop" src="/img/store-heading.jpg">
        <HeaderPrimary>Shop</HeaderPrimary>
      </Heading>
      <Store.Section>
        <HeaderSecondary>Rafts</HeaderSecondary>
        <Store.ImgWrapper src="/img/store-raft.jpg" />
        <Store.Description>
          {' '}
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Illo
          excepturi eius illum minima, sequi, voluptatem deserunt sed earum
          doloribus, accusantium voluptatibus. Quas quam itaque fugiat dolore
          ipsa dicta eligendi libero quis, sint eum nihil similique consequatur
          illo laboriosam incidunt accusamus sequi modi qui asperiores
          accusantium dolor molestias! Maxime placeat, quasi possimus incidunt
          numquam adipisci tempore dolor tempora. Corporis nesciunt maiores
          tenetur, quis dolorum ad ipsam impedit! Ipsum, modi inventore!
          Officiis, incidunt assumenda facilis neque voluptas adipisci dicta,
          sapiente eveniet qui tempora quis iure nobis, recusandae temporibus ex
          explicabo eos ad suscipit placeat reiciendis.
        </Store.Description>
        <Button primary="true" to="/rafts" onClick={() => scrollToTop()}>
          Shop all rafts
        </Button>
      </Store.Section>
      <Store.Line />
      <Store.Section>
        <HeaderSecondary>Kayaks</HeaderSecondary>
        <Store.ImgWrapper src="/img/store-kayak.jpg" />
        <Store.Description>
          {' '}
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Illo
          excepturi eius illum minima, sequi, voluptatem deserunt sed earum
          doloribus, accusantium voluptatibus. Quas quam itaque fugiat dolore
          ipsa dicta eligendi libero quis, sint eum nihil similique consequatur
          illo laboriosam incidunt accusamus sequi modi qui asperiores
          accusantium dolor molestias! Maxime placeat, quasi possimus incidunt
          numquam adipisci tempore dolor tempora. Corporis nesciunt maiores
          tenetur, quis dolorum ad ipsam impedit! Ipsum, modi inventore!
          Officiis, incidunt assumenda facilis neque voluptas adipisci dicta,
          sapiente eveniet qui tempora quis iure nobis, recusandae temporibus ex
          explicabo eos ad suscipit placeat reiciendis.
        </Store.Description>
        <Button primary="true" to="/kayaks" onClick={() => scrollToTop()}>
          Shop all kayaks
        </Button>
      </Store.Section>
      <Store.Line />
      <Store.Section>
        <HeaderSecondary>Stand-Up Paddleboards</HeaderSecondary>
        <Store.ImgWrapper src="/img/store-SUP.jpg" />
        <Store.Description>
          {' '}
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Illo
          excepturi eius illum minima, sequi, voluptatem deserunt sed earum
          doloribus, accusantium voluptatibus. Quas quam itaque fugiat dolore
          ipsa dicta eligendi libero quis, sint eum nihil similique consequatur
          illo laboriosam incidunt accusamus sequi modi qui asperiores
          accusantium dolor molestias! Maxime placeat, quasi possimus incidunt
          numquam adipisci tempore dolor tempora. Corporis nesciunt maiores
          tenetur, quis dolorum ad ipsam impedit! Ipsum, modi inventore!
          Officiis, incidunt assumenda facilis neque voluptas adipisci dicta,
          sapiente eveniet qui tempora quis iure nobis, recusandae temporibus ex
          explicabo eos ad suscipit placeat reiciendis.
        </Store.Description>
        <Button primary="true" to="/SUPs" onClick={() => scrollToTop()}>
          Shop all SUPs
        </Button>
      </Store.Section>
    </Store.Container>
  );
}
