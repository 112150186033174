import React from 'react';

import * as CTA from '../../styles/components/Landing/CTA';
import { HeaderSecondary } from '../../styles/components/Reusable';
import { scrollToTop } from '../../helpers';

export default function index() {
  return (
    <CTA.Container>
      <HeaderSecondary>Inflatables</HeaderSecondary>
      <CTA.CardWrapper>
        <CTA.Card src='/img/CTA-raft.jpg'>
          <CTA.Title>Rafts</CTA.Title>
          <CTA.Button
            onClick={() => {
              scrollToTop();
            }}
            to='/rafts'
          >
            Visit Catalog
          </CTA.Button>
        </CTA.Card>
        <CTA.Card src='/img/CTA-kayak.jpg'>
          <CTA.Title>Kayaks</CTA.Title>
          <CTA.Button
            onClick={() => {
              scrollToTop();
            }}
            to='/kayaks'
          >
            Visit Catalog
          </CTA.Button>
        </CTA.Card>
        <CTA.Card src='/img/CTA-IPB.jpg'>
          <CTA.Title>SUPs</CTA.Title>
          <CTA.Button
            onClick={() => {
              scrollToTop();
            }}
            to='/SUPs'
          >
            Visit Catalog
          </CTA.Button>
        </CTA.Card>
      </CTA.CardWrapper>
    </CTA.Container>
  );
}
