import React, { useEffect } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import _ from 'lodash';
import { loadStripe } from '@stripe/stripe-js';
import Media from 'react-media';

import {
  fetchCart,
  addToCart,
  reduceItemCart,
  removeItemCart,
  clearCart,
  displayMessage
} from '../../actions';
import * as Cart from '../../styles/components/Cart';
import { Button, HeaderSecondary } from '../../styles/components/Reusable';
import { VscTrash } from 'react-icons/vsc';
import { AiOutlineClose } from 'react-icons/ai';
import { IoAdd, IoRemove } from 'react-icons/io5';
import { scrollToTop } from '../../helpers';

//initialize stripe, for creating checkout
const stripePromise = loadStripe(
  'pk_test_51IPUouGCXBFaStpHIJDxePFhJhhztyFjkidWQozAVMx7lXfnAYwlbv4QzTskL6Jhy8Qr9srYKOE6zo3IS6ymktfd00qD4NasV7'
);

function Index(props) {
  const {
    cart,
    fetchCart,
    addToCart,
    reduceItemCart,
    removeItemCart,
    clearCart,
    displayMessage
  } = props;

  useEffect(() => {
    const updateItems = async () => {
      setTimeout(fetchCart, 500);
    };
    updateItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCheckoutClick = async () => {
    const stripe = await stripePromise;

    const res = await axios.post(
      `/api/v1/create-checkout-session`,
      {},
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json'
        },
        withCredentials: true
      }
    );

    const session = await res.data;

    const result = await stripe.redirectToCheckout({
      sessionId: session.data.id
    });

    if (result.error) {
      displayMessage(result.error.message);
    }
  };

  const renderTags = () => {
    return (
      <Cart.Head>
        <Cart.DetailsTag>Details</Cart.DetailsTag>
        <Cart.TotalTag>Subtotal</Cart.TotalTag>
      </Cart.Head>
    );
  };

  const renderCart = () => {
    if (cart.data.products) {
      return cart.data.products.map((product, i) => {
        const { item } = product;

        const renderColors = () => {
          return _.map(product.colors, (value, key) => {
            return (
              <Cart.Color color={key} key={key}>
                {key}
              </Cart.Color>
            );
          });
        };

        if (!item) {
          return '';
        }

        return (
          <Cart.Card key={item.id + i}>
            <Cart.Details>
              <Cart.CoverImg src={item.coverImage} alt={item.title} />
              <Media
                query='(max-width: 768px)'
                render={(matches) => {
                  if (matches) {
                    return <Cart.DetailsSmall>Details</Cart.DetailsSmall>;
                  }
                }}
              />
              <Cart.Info>
                <Cart.TitleLink to={`/${item.type}s/${item._id}`}>
                  {item.title} ({Object.keys(product.colors)[0]})
                </Cart.TitleLink>
                <p>Color: {renderColors()}</p>
                <p>
                  Price: <Cart.Price>${item.price} each</Cart.Price>
                </p>
                <Cart.Quantity>
                  Quantity:{' '}
                  <Cart.QuantityInput>
                    <button
                      onClick={() =>
                        addToCart(item.id, 1, Object.keys(product.colors)[0])
                      }
                      onKeyPress={(e) =>
                        e.key === 'Enter'
                          ? addToCart(
                              item.id,
                              1,
                              Object.keys(product.colors)[0]
                            )
                          : ''
                      }
                    >
                      <IoAdd />
                    </button>
                    <Cart.QuantitySpan>
                      {_.round(product.qty, 2)}
                    </Cart.QuantitySpan>
                    <button
                      onClick={() =>
                        reduceItemCart(item.id, Object.keys(product.colors)[0])
                      }
                      onKeyPress={(e) =>
                        e.key === 'Enter'
                          ? reduceItemCart(
                              item.id,
                              Object.keys(product.colors)[0]
                            )
                          : ''
                      }
                    >
                      <IoRemove />
                    </button>
                  </Cart.QuantityInput>
                </Cart.Quantity>
              </Cart.Info>
            </Cart.Details>
            <Media
              query='(max-width: 768px)'
              render={(matches) => {
                if (matches) {
                  return <Cart.TotalTagSmall>Subtotal</Cart.TotalTagSmall>;
                }
              }}
            />
            <Cart.Subtotal>
              <Cart.TotalPrice>${_.round(product.price, 2)}</Cart.TotalPrice>
            </Cart.Subtotal>
            <Cart.Options>
              <Cart.ClearItem
                tabIndex='0'
                onKeyPress={(e) =>
                  e.key === 'Enter' ? removeItemCart(item._id) : ''
                }
                onClick={() =>
                  removeItemCart(item._id, Object.keys(product.colors)[0])
                }
              >
                <VscTrash />
                <Cart.Tooltip>Remove item from cart</Cart.Tooltip>
              </Cart.ClearItem>
              <Media
                query='(max-width: 768px)'
                render={(matches) => {
                  if (matches) {
                    return (
                      <Cart.RemoveTag>Remove item from cart</Cart.RemoveTag>
                    );
                  }
                }}
              />
            </Cart.Options>
          </Cart.Card>
        );
      });
    }
  };

  if (!cart.results || !cart.data)
    return (
      <Cart.Container>
        <HeaderSecondary>Cart Empty</HeaderSecondary>
        <Button to='/store' style={{ color: '#fff' }}>
          Shop all products now
        </Button>
      </Cart.Container>
    );
  return (
    <Cart.Container>
      <HeaderSecondary>Shopping Cart</HeaderSecondary>
      <Media query='(min-width: 769px)' render={renderTags} />
      <Cart.Content>
        {renderCart()}
        <Cart.ClearCart
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              clearCart();
              scrollToTop();
            }
          }}
          onClick={() => {
            clearCart();
            scrollToTop();
          }}
        >
          <AiOutlineClose />
          Clear cart
        </Cart.ClearCart>
        <Cart.PreCheckout>
          <Cart.CheckoutInfo>
            <Cart.CheckoutTotal>
              <Cart.CheckoutLabel>Subtotal</Cart.CheckoutLabel>
              <Cart.CheckoutTotalPrice>
                ${_.round(cart.data.totalPrice, 2)}
                <Cart.TotalSpan>(before shipping & taxes)</Cart.TotalSpan>
              </Cart.CheckoutTotalPrice>
            </Cart.CheckoutTotal>
          </Cart.CheckoutInfo>
          <Button to='#' onClick={() => onCheckoutClick()}>
            Proceed to Checkout
          </Button>
        </Cart.PreCheckout>
      </Cart.Content>
    </Cart.Container>
  );
}

const mapStateToProps = ({ cart }) => {
  return { cart };
};

export default connect(mapStateToProps, {
  fetchCart,
  addToCart,
  reduceItemCart,
  removeItemCart,
  clearCart,
  displayMessage
})(Index);
