import styled from 'styled-components';
import { device } from '../devices';

export const Container = styled.div`
  margin-top: 8rem;
  padding: 3rem 0;

  background-color: ${(props) => props.theme.colorLightGray3};
`;

export const Main = styled.main`
  width: 90%;
  margin: 0 auto;

  background-color: ${(props) => props.theme.colorLightGray1};

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SuccessImg = styled.span`
  display: block;
  font-size: 20rem;

  margin-bottom: -3rem;

  color: ${(props) => props.theme.colorError};
`;

export const Details = styled.p`
  width: 60%;
  text-align: center;

  margin-bottom: 6rem;

  font-size: 2rem;

  @media ${device.mobileL} {
    width: 85%;
  }
`;

export const Highlight = styled.span`
  color: ${(props) => props.theme.colorTertiaryDark};
`;
