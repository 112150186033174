import { DISPLAY_MESSAGE } from '../actions/types';

const messageReducer = (
  state = {
    message: '',
    time: 0,
    error: false
  },
  action
) => {
  switch (action.type) {
    case DISPLAY_MESSAGE:
      return { ...action.payload };
    default:
      return state;
  }
};

export default messageReducer;
