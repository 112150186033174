import React from 'react';
import { reduxForm, Field } from 'redux-form';
import { signupUser, redirectTo, displayMessage } from '../actions';
import { connect } from 'react-redux';

import * as Log from '../styles/components/Form';
import { HeaderSecondary, ButtonSubmit } from '../styles/components/Reusable';
import { Redirect } from 'react-router-dom';

const renderInput = ({ input, meta: { touched, invalid }, ...rest }) => (
  <Log.Input
    onChange={(event, value) => input.onChange(value)}
    error={touched && invalid}
    {...input}
    {...rest}
  />
);

async function onSignupSubmit(values, dispatch) {
  try {
    await signupUser(values)(dispatch);
    values.email = '';
    values.password = '';
    await dispatch(redirectTo('/'));
  } catch (err) {
    await dispatch(displayMessage(err.message, 5, true));
  }
}

const SignUp = props => {
  const { handleSubmit, redirect } = props;

  if (redirect) {
    return <Redirect to={redirect} from="/signup" />;
  }

  return (
    <Log.Container>
      <Log.Form onSubmit={handleSubmit}>
        <HeaderSecondary>Signup</HeaderSecondary>
        <Log.Group>
          <Log.Label htmlFor="email">Email</Log.Label>
          <Field
            name="email"
            type="email"
            label="email"
            component={renderInput}
            placeholder="example@email.com"
            id="email"
          />
        </Log.Group>
        <Log.Group>
          <Log.Label htmlFor="password">Password</Log.Label>
          <Field
            label="password"
            name="password"
            type="password"
            component={renderInput}
            placeholder="**********"
            id="password"
          />
        </Log.Group>
        <Log.Group>
          <Log.Label htmlFor="passwordConfirm">Confirm password</Log.Label>
          <Field
            label="passwordConfirm"
            name="passwordConfirm"
            type="password"
            component={renderInput}
            placeholder="**********"
            id="passwordConfirm"
          />
        </Log.Group>
        <Log.Group>
          <Log.ButtonWrapper>
            <ButtonSubmit type="submit" width="15rem" value="Login" />
          </Log.ButtonWrapper>
        </Log.Group>
      </Log.Form>
    </Log.Container>
  );
};

const mapStateToProps = ({ redirect }) => {
  return { redirect };
};

const connectedSignup = connect(mapStateToProps)(SignUp);

export default reduxForm({ form: 'signup', onSubmit: onSignupSubmit })(
  connectedSignup
);
