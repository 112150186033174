import React from 'react';

import { scrollToTop } from '../../helpers';
import * as Footer from '../../styles/components/FooterStyles';
import { BiCopyright } from 'react-icons/bi';
import { GrFacebook } from 'react-icons/gr';
import { MdEmail } from 'react-icons/md';
import { AiOutlinePhone } from 'react-icons/ai';

export default function index() {
  const handleEmail = (e) => {
    e.preventDefault();

    window.open('mailto:nelsnath@gmail.com');
  };

  return (
    <Footer.Container>
      <Footer.Links>
        <Footer.LinksHeader>Site Links</Footer.LinksHeader>
        <Footer.LinkItem to='/store' onClick={() => scrollToTop()}>
          Store Page
        </Footer.LinkItem>
        <Footer.LinkItem to='/rafts' onClick={() => scrollToTop()}>
          - Rafts
        </Footer.LinkItem>
        <Footer.LinkItem to='/kayaks' onClick={() => scrollToTop()}>
          - Kayaks
        </Footer.LinkItem>
        <Footer.LinkItem to='/SUPs' onClick={() => scrollToTop()}>
          - SUPs
        </Footer.LinkItem>
        {/* <Footer.LinkItem to='/about-us' onClick={() => scrollToTop()}>
          About Us
        </Footer.LinkItem> */}
      </Footer.Links>
      <Footer.Contact>
        <Footer.LinksHeader>Contact Us</Footer.LinksHeader>
        <Footer.ContactItemWrapper>
          <Footer.ContactItem
            to='//www.facebook.com/Teton-Wilderness-Supply-296883170993525/'
            target='_blank'
            rel='noopener noreferrer'
            type='facebook'
          >
            <GrFacebook />
            Facebook
          </Footer.ContactItem>
          <Footer.ContactItem
            type='email'
            to='#'
            onClick={(e) => handleEmail(e)}
          >
            <MdEmail />
            Email
          </Footer.ContactItem>
          <Footer.ContactItem type='phone' to='#'>
            <AiOutlinePhone />
            (208)406-6977
          </Footer.ContactItem>
        </Footer.ContactItemWrapper>
      </Footer.Contact>
      <Footer.Legal>
        <Footer.LinksHeader>Legal</Footer.LinksHeader>
        <Footer.LegalLink to='/legal#terms'>Terms of Service</Footer.LegalLink>
        <Footer.LegalLink to='/legal#privacy'>Privacy policy</Footer.LegalLink>
        <Footer.LegalLink to='/legal#cookies'>Cookie policy</Footer.LegalLink>
        <Footer.Copywrite>
          Copywrite TetonWildernessSupply LLC 2021 <BiCopyright />
          <br />
          All rights reserved
        </Footer.Copywrite>
      </Footer.Legal>
    </Footer.Container>
  );
}
