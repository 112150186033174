import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import * as Cook from '../styles/components/CookieStyles';
import { checkCookies, acceptCookies } from '../actions';
import { scrollToTop } from '../helpers';

export const CookieLegal = ({
  checkCookies,
  acceptCookies,
  cookiesAccepted
}) => {
  useEffect(() => {
    if (!cookiesAccepted) {
      checkCookies();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookiesAccepted]);

  if (cookiesAccepted) return '';

  return (
    <Cook.Container>
      <Cook.Text>
        This website uses cookies to improve user experience.{' '}
        <Cook.TextLink to='/legal#cookies' onClick={() => scrollToTop()}>
          Click here
        </Cook.TextLink>{' '}
        for more info regarding our cookie and privacy policy.
      </Cook.Text>
      <Cook.AcceptButton onClick={() => acceptCookies()}>
        I understand
      </Cook.AcceptButton>
    </Cook.Container>
  );
};

const mapStateToProps = (state) => ({ cookiesAccepted: state.cookiesAccepted });

const mapDispatchToProps = { acceptCookies, checkCookies };

export default connect(mapStateToProps, mapDispatchToProps)(CookieLegal);
