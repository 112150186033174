import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { RiArrowDownSLine } from 'react-icons/ri';
import { BsSearch } from 'react-icons/bs';
import { FiShoppingCart } from 'react-icons/fi';

import { device } from '../devices';

export const NavWrapper = styled.nav`
  height: 8rem;
  background-color: transparent;

  margin-bottom: -8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${(props) => props.theme.colorLightGray1};
  position: relative;
  z-index: 10;
  transition: all 0.2s;

  &:hover {
    background-color: rgba(${(props) => props.theme.colorDarkGray4RGB}, 0.7);
  }

  @media ${device.tablet} {
    &:hover,
    &:focus {
      background-color: transparent;
    }
  }
`;

export const LeftWrapper = styled.div`
  flex: 0 0 40%;

  display: flex;
  align-items: center;
`;

export const Logo = styled(Link)`
  cursor: pointer;
  transition: all 0.1s;
  margin-top: 1rem;
  margin-left: 1.5rem;

  &:hover {
    transform: translateY(-2px);
  }
`;

export const LogoImg = styled.img`
  height: 8rem;

  @media ${device.mobileS} {
    height: 6rem;
  }
`;

export const DropdownWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-left: 3.5rem;
  transition: all 0.2s;

  &:hover {
    border: 1px solid ${(props) => props.theme.colorPrimary};
  }
`;

export const DropdownToggle = styled.div`
  width: 10rem;
  height: 8rem;

  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 0.25rem;

  &:hover {
    color: ${(props) => props.theme.colorPrimary};
  }
`;

export const DropdownLink = styled(Link)`
  margin-right: 1rem;
  cursor: pointer;
  transition: all 0.1s;

  &:link,
  &:visited {
    color: inherit;
    text-decoration: none;
    font-size: 2rem;
  }

  &:active {
    color: ${(props) => props.theme.colorPrimaryLight};
    transform: translateY(-1px);
  }
`;

export const DropdownIcon = styled(RiArrowDownSLine)`
  height: 1.5rem;
  width: 1.5rem;
`;

export const DropdownOptions = styled.div`
  position: absolute;
  top: 8rem;
  left: -0.1rem;
  min-height: 15rem;
  min-width: 65rem;
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.4s;
  box-shadow: 0 2rem 5rem rgba(0, 0, 0, 0.1);
  border: 1px solid ${(props) => props.theme.colorPrimaryLight};
  background-color: rgba(${(props) => props.theme.colorDarkGray4RGB}, 0.7);

  ${DropdownWrapper}:hover & {
    visibility: visible;
    opacity: 1;
  }
`;

export const DropdownOptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1.2rem;

  &:not(:last-child) {
    margin-right: 3rem;
    border-right: 1px solid ${(props) => props.theme.colorPrimaryLight};
  }
`;

export const DropdownFeaturedWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
`;

export const DropdownHeader = styled.h3`
  padding-bottom: 0.3rem;
  margin-bottom: 1.3rem;
  border-bottom: 1px solid ${(props) => props.theme.colorPrimaryLight};
  font-weight: 400;
`;

export const DropdownOption = styled(Link)`
  flex: 50% 0 0;
  height: 4rem;

  &:link,
  &:visited {
    text-decoration: none;
    color: inherit;
    font-size: 1.8rem;
    font-weight: ${(props) => props.theme.lightFontWeight};
    line-height: 4rem;
  }

  &:hover {
    background-color: rgba(${(props) => props.theme.colorLightGray3RGB}, 0.8);
    color: ${(props) => props.theme.colorPrimaryDark};
  }
`;

export const DropdownOptionMain = styled(Link)`
  width: 23rem;
  height: 5rem;
  text-align: center;
  line-height: 5rem;
  margin-bottom: 1.2rem;

  &:link,
  &:visited {
    color: inherit;
    text-decoration: none;
    font-size: 2rem;
    font-weight: ${(props) => props.theme.lightFontWeight};
  }

  &:hover {
    background-color: rgba(${(props) => props.theme.colorLightGray3RGB}, 0.8);
    color: ${(props) => props.theme.colorPrimaryDark};
  }
`;

export const AboutUs = styled(Link)`
  text-align: center;
  height: 8rem;
  width: 10rem;
  padding-left: 1rem;
  padding-right: 1rem;
  line-height: 8rem;
  transition: all 0.2s;

  &:link,
  &:visited {
    color: inherit;
    text-decoration: none;
    font-size: 2rem;

    &:hover {
      color: ${(props) => props.theme.colorPrimary};
      border: 1px solid ${(props) => props.theme.colorPrimary};
    }
  }

  &:active {
    color: ${(props) => props.theme.colorPrimaryLight};
    transform: translateY(-1px);
  }
`;

export const Search = styled.form`
  flex: 0 0 20%;
  display: flex;
`;

export const SearchInput = styled.input`
  flex: 1;

  font-family: inherit;
  font-size: inherit;
  color: ${(props) => props.theme.colorDarkGray3};
  outline: none;

  border: 1px solid ${(props) => props.theme.colorLightGray3};
  border-radius: 200px;

  margin-right: -3.5rem;
  padding: 1rem 2rem;

  background-color: ${(props) => props.theme.colorLightGray1};
  transition: all 0.3s;

  &:hover {
    border: 2px solid ${(props) => props.theme.colorPrimaryLight};
  }

  &:focus {
    border: 2px solid ${(props) => props.theme.colorPrimaryDark};
  }
`;

export const SearchButton = styled.button`
  flex: 0 0 10%;

  border: none;
  background-color: inherit;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &:active {
    transform: translateY(2px);
  }
`;

export const SearchIcon = styled(BsSearch)`
  height: 1.7rem;
  width: 1.7rem;
  color: ${(props) => props.theme.colorDarkGray1};

  ${SearchButton}:hover & {
    color: ${(props) => props.theme.colorDarkGray3};
  }
`;

export const RightWrapper = styled.div`
  flex: 0 0 40%;
  display: flex;

  justify-content: flex-end;
  align-items: center;
`;

export const CartWrapper = styled(Link)`
  display: flex;
  transition: all 0.1s;
  text-decoration: none;

  &:link,
  &:visited {
    color: inherit;
  }
`;

export const CartIcon = styled(FiShoppingCart)`
  height: 2.4rem;
  width: 2.4rem;
  margin-right: 3.5rem;

  &:hover {
    color: ${(props) => props.theme.colorPrimary};
  }

  &:active {
    color: ${(props) => props.theme.colorPrimaryLight};
    transform: translateY(2px);
  }
`;

export const Admin = styled.div`
  margin-top: ${(props) => (props.open ? '1rem' : '')};
  margin-right: ${(props) => (props.open ? '' : '2rem')};
`;

export const Expand = styled.button`
  align-self: flex-end;
  transition: all 0.3s;

  background-color: transparent;
  border: none;
  margin-right: 2rem;

  opacity: ${(props) => (props.changing ? '0' : '')};
  box-shadow: ${(props) => props.theme.shadowLight};

  & > svg {
    font-size: 5rem;
    color: ${(props) => props.theme.colorPrimaryLight};
    color: ${(props) => (props.open ? props.theme.colorError : '')};
  }

  &:focus {
    outline: none;
  }
`;

export const ExpandMenu = styled.div`
  transition: all 0.4s;
  padding: 4rem 0;

  width: 100%;
  min-height: 10rem;

  opacity: ${(props) => (props.open ? '1' : '0')};
  transform: ${(props) => (props.open ? 'translateY(0)' : 'translateY(15rem)')};

  background-color: ${(props) => props.theme.colorPrimary};
  background-image: linear-gradient(
    to bottom right,
    ${(props) => props.theme.colorPrimaryLight},
    ${(props) => props.theme.colorPrimaryDark}
  );
  background-repeat: no-repeat;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ExpandItem = styled(Link)`
  position: relative;
  z-index: 20;

  font-size: 3.5rem;
  font-weight: 100;
  color: ${(props) => props.theme.colorLightGray1};

  transition: all 0.3s;

  &:link,
  &:visited {
    text-decoration: none;
    color: ${(props) => props.theme.colorLightGray1};
  }

  &:hover,
  &:active {
    transform: scale(1.1);
  }

  & > svg {
    font-size: 3rem;
    text-align: center;
    position: absolute;
    top: 1.7rem;
    margin-left: 1rem;
  }
`;

export const ExpandDropdownLink = styled.div`
  position: relative;
  z-index: 20;

  font-size: 3.5rem;
  font-weight: 100;
  color: ${(props) => props.theme.colorLightGray1};

  transition: all 0.3s;
  cursor: pointer;

  & > svg {
    font-size: 3rem;
    text-align: center;
    position: absolute;
    top: 1.7rem;
  }
`;

export const ExpandDropdown = styled.div`
  transition: all 0.4s;

  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-bottom: 1rem;
  font-style: italic;

  opacity: ${(props) => (props.open ? '1' : '0')};
  display: ${(props) => (props.open ? '' : 'none')};
  transform: ${(props) =>
    props.open ? 'translateY(0px)' : 'translateY(10rem)'};
`;

export const ExpandDropdownItem = styled(ExpandItem)`
  width: 70%;
  font-size: 3rem;

  border-left: 1px solid ${(props) => props.theme.colorLightGray1};
  padding-left: 1rem;
  margin-left: 8rem;
`;

export const ExpandLine = styled.hr`
  width: 20rem;

  background-color: ${(props) => props.theme.colorLightGray1};
  color: transparent;

  opacity: 0.5;

  border: none;
  height: 1px;

  backface-visibility: hidden;
`;
