export const LOGIN_USER = 'LOGIN_USER';
export const FETCH_USER = 'FETCH_USER';
export const ACCEPT_COOKIE = 'ACCEPT_COOKIE';
export const CHECK_COOKIE = 'CHECK_COOKIE';
export const REDIRECT_TO = 'REDIRECT_TO';
export const FETCH_PRODUCTS = 'FETCH_PRODUCTS';
export const FETCH_PRODUCT = 'FETCH_PRODUCT';
export const SET_UPDATE_PRODUCT = 'SET_UPDATE_ID';
export const CLEAR_UPDATE = 'clearupdate';
export const CLEAR_PRODUCT = 'CLEAR_PRODUCT';
export const CLEAR_PRODUCTS = 'CLEAR_PRODUCTS';
export const FETCH_REVIEWS = 'FETCH_REVIEWS';
export const CLEAR_REVIEWS = 'CLEAR_REVIEWS';
export const FETCH_CART = 'FETCH_CART';
export const FETCH_ORDERS = 'FETCH_ORDERS';
export const DELETE_ORDER = 'DELETE_ORDER';
export const DISPLAY_MESSAGE = 'DISPLAY_MESSAGE';
export const CREATE_CHECKOUT = 'CREATE_CHECKOUT';
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';
export const DELETE_PRODUCT = 'REMOVE_PRODUCT';
