import React from 'react';
import { reduxForm, Field } from 'redux-form';
import { loginUser, redirectTo } from '../actions';
import { connect } from 'react-redux';

import * as Log from '../styles/components/Form';
import { HeaderSecondary, ButtonSubmit } from '../styles/components/Reusable';
import { Redirect } from 'react-router-dom';

const renderInput = ({ input, meta: { touched, invalid }, ...rest }) => (
  <Log.Input
    onChange={(event, value) => input.onChange(value)}
    error={touched && invalid}
    {...input}
    {...rest}
  />
);

async function onLoginSubmit(values, dispatch) {
  await loginUser(values)(dispatch);
  values.email = '';
  values.password = '';
  await dispatch(redirectTo('/'));
}

const Login = props => {
  const { handleSubmit, redirect } = props;

  if (redirect) {
    return <Redirect to={redirect} from="/603ab800b9e63941b40a479a" />;
  }

  return (
    <Log.Container>
      <Log.Form onSubmit={handleSubmit}>
        <HeaderSecondary>Login</HeaderSecondary>
        <Log.Group>
          <Log.Label htmlFor="email">Email</Log.Label>
          <Field
            name="email"
            type="email"
            label="email"
            component={renderInput}
            placeholder="example@email.com"
            id="email"
          />
        </Log.Group>
        <Log.Group>
          <Log.Label htmlFor="password">Password</Log.Label>
          <Field
            label="password"
            name="password"
            type="password"
            component={renderInput}
            placeholder="**********"
            id="password"
          />
        </Log.Group>
        <Log.Group>
          <Log.ButtonWrapper>
            <ButtonSubmit type="submit" width="15rem" value="Login" />
          </Log.ButtonWrapper>
        </Log.Group>
      </Log.Form>
    </Log.Container>
  );
};

const mapStateToProps = ({ redirect }) => {
  return { redirect };
};

const connectedLogin = connect(mapStateToProps)(Login);

export default reduxForm({ form: 'login', onSubmit: onLoginSubmit })(
  connectedLogin
);
